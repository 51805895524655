export default {
  name: "ExamReport",
  data() {
    return {
      editVisible: false
    };
  },
  methods: {
    onCreateMore() {
      this.editVisible = true;
    }
  }
};