import SvgIcon from "@/components/SvgIcon";
import OssUploader from "@/components/OssUploader";
export default {
  name: "FileImportPage",
  components: {
    SvgIcon,
    OssUploader
  },
  data() {
    return {
      uploaderVersion: 0
    };
  },
  methods: {
    downloadFile(url) {
      window.open(url, '_blank');
    },
    onFileUploaded(url) {
      this.$emit('onFileUploaded', url);
      this.uploaderVersion++;
    }
  }
};