import "core-js/modules/es.array.push.js";
import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "DialogView",
  data() {
    return {
      dialogVisible: false,
      detail: {},
      form: {},
      video_meta: {},
      eventActions: {},
      dataSource: {},
      dataSourceMap: {},
      clearKeys: {},
      ossClient: null,
      ossInitState: false,
      uploading: false,
      ossDomain: null,
      ossFolder: null
    };
  },
  props: {
    visibleControl: {
      type: Boolean,
      default: false
    },
    dataId: {
      type: Number,
      default: 0
    },
    bindId: {
      type: Number,
      default: -1
    },
    formOverrideData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    visibleControl(b) {
      this.dialogVisible = b;
      if (b) {
        this.render();
      }
    },
    formOverrideData(o) {
      for (let k in o) {
        this.form[k] = o[k];
      }
    }
  },
  methods: {
    handlePlaceSelect(item) {
      this.$emit('onPlaceSelected', item);
    },
    queryPlaceAsync(queryString, done) {
      api.queryPlace({
        search: queryString
      }).then(ret => {
        for (let r of ret) {
          r.value = r.name;
        }
        done(ret);
      });
    },
    onLoadedVideoMetadata(e, file) {
      let url = file.response == null ? file.url : file.response.url;
      this.video_meta[new URL(url).pathname] = {
        'width': e.target.videoWidth,
        'height': e.target.videoHeight,
        'duration': e.target.duration
      };
    },
    isMp4(file) {
      if (file.response != null) {
        return file.response.url.endsWith('mp4');
      }
      return file.url.endsWith('mp4');
    },
    isImage(file) {
      let url = file.response == null ? file.url : file.response.url;
      return url.endsWith('jpg') || url.endsWith('jpeg') || url.endsWith('png') || url.endsWith('svg') || url.endsWith('gif') || url.endsWith('bmp');
    },
    fnLazyLoad(itemKey, source, node, resolve) {
      let query = {};
      if (node.level > 0) {
        query[itemKey] = node.value;
      }
      api.get(source, query).then(ret => {
        resolve(ret);
      });
    },
    initOssClint() {
      if (this.ossInitState) {
        console.log('OSS已经完成初始化!');
        return;
      }
      this.ossInitState = true;
      let OSS = require('ali-oss');
      api.getOssKeySecret().then(cfg => {
        this.ossClient = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true
        });
        this.ossDomain = cfg.domain;
        this.ossFolder = cfg.folder;
      });
    },
    beforeImageUpload(file, suffixLimit) {
      if (!suffixLimit) {
        return file != null;
      }
      let check = false;
      for (let sf of suffixLimit) {
        if (file.name.endsWith(sf)) {
          check = true;
        }
      }
      if (!check) {
        ElMessage.error('仅支持' + suffixLimit.join('、') + '格式');
      }
      return check;
    },
    handleRemove(file, formKey) {
      let url = file.response == null ? file.url : file.response.url;
      console.log('deleting url', url);
      console.log('form', this.form);
      if (this.form[formKey + '_init'] != null) {
        console.log('deleting', formKey + '_init');
        this.form[formKey + '_init'] = this.form[formKey + '_init'].filter(itm => itm.url !== url);
      }
      if (this.form[formKey] != null) {
        console.log('deleting', formKey);
        this.form[formKey] = this.form[formKey].filter(itm => itm !== url);
      }
      this.showUploader(formKey);
    },
    handleImageUploadSuccess(formKey, url, r, limit) {
      if (this.ossDomain) {
        url = this.ossDomain + '/' + r.name;
      }
      this.uploading = false;
      if (this.form[formKey]) {
        this.form[formKey].push(url);
      } else {
        this.form[formKey] = [url];
      }
      if (this.form[formKey].length >= limit) {
        this.hideUploader(formKey);
      }
    },
    hideUploader(containerId) {
      let container = document.querySelector('#' + containerId);
      let elements = container.querySelectorAll('.el-upload--picture-card');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = 'none';
      }
    },
    showUploader(containerId) {
      let container = document.querySelector('#' + containerId);
      let elements = container.querySelectorAll('.el-upload--picture-card');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = 'inline-flex';
      }
    },
    handleUploadImageRemove(formKey, d) {
      let fileUrl = d.response ? d.response.url : d.url;
      let filtered = [];
      for (let v of this.form[formKey]) {
        if (v !== fileUrl) {
          filtered.push(v);
        }
      }
      this.form[formKey] = filtered;
      this.showUploader(formKey);
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    uploadRequest(options) {
      try {
        this.uploading = true;
        let file = options.file; // 拿到 file
        let fileType = file.name.substr(file.name.lastIndexOf('.'));
        let fileName = (this.ossFolder ? this.ossFolder + '/' : 'user-upload/') + this.guid() + fileType;
        // 上传文件,这里是上传到OSS的 uploads文件夹下
        this.ossClient.put(fileName, file).then(res => {
          if (res.res.statusCode === 200) {
            options.onSuccess(res);
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    queryDataSource(dataKey) {
      if (dataKey && this.clearKeys[dataKey]) {
        //需要清除其他key的情形
        for (let cl of this.clearKeys[dataKey]) {
          this.form[cl] = null;
        }
        this.$emit('formDataChange', {
          'key': dataKey,
          'value': this.form[dataKey]
        });
      }
      for (let k of Object.keys(this.dataSourceMap)) {
        if (dataKey && dataKey === k) {
          //当前key不更新
          continue;
        }
        let query = {};
        for (let k of Object.keys(this.form)) {
          if (this.form[k] != null && !(this.form[k] instanceof Array)) {
            query[k] = this.form[k];
          }
        }
        api.get(this.dataSourceMap[k], query).then(ret => {
          this.dataSource[k] = ret;
        });
      }
    },
    onDialogClose() {
      this.$emit('onDialogClose');
      this.$emit('close');
      this.detail = {};
      this.form = {};
    },
    onButtonClick(action) {
      if (action === 'submit') {
        this.form['videoMeta'] = this.video_meta;
        this.$emit('submit', this.form);
      } else {
        this.onDialogClose();
      }
    },
    render() {
      if (this.dataId <= 0 || !this.visibleControl) {
        return;
      }
      api.getDialogForm(this.dataId, this.bindId).then(ret => {
        this.detail = ret;
        for (let part of ret.details) {
          for (let d of part.items) {
            if (d['type'] === 'cascader' || d['type'] === 'select' || d['type'] === 'radio' || d['type'] === 'multiSelect') {
              this.form[d['name']] = d['selected'];
            } else if (d['type'] === 'tree') {
              this.form[d['name']] = d['selected'];
              this.eventActions[d['name']] = (checkedNodes, checkedKeys) => {
                this.form[d['name']] = checkedKeys.checkedKeys;
              };
            } else if (d['type'] === 'uploadImage') {
              this.initOssClint();
              let fileList = [];
              let files = [];
              if (d['value']) {
                //有上传列表预览
                for (let g of d['value']) {
                  if (g.imageUrl) {
                    fileList.push({
                      name: g.id,
                      url: g.imageUrl
                    });
                    files.push(g.imageUrl);
                  }
                }
              }
              this.form[d['name']] = files;
              this.form[d['name'] + '_init'] = fileList;
              if (files.length >= d.limit) {
                setTimeout(() => {
                  this.hideUploader(d['name']);
                }, 500);
              }
            } else {
              this.form[d['name']] = d['value'];
            }
            if (d['dataSource']) {
              this.dataSourceMap[d.name] = d.dataSource;
            }
            if (d['clearKeys']) {
              this.clearKeys[d.name] = d.clearKeys;
            }
          }
        }
        this.queryDataSource();
      });
    }
  },
  mounted() {
    this.render();
  }
};