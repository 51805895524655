import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ff0e43c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "grid-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  return _openBlock(), _createBlock(_component_el_col, {
    span: 24
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      id: "amapContainer",
      style: _normalizeStyle('width:100%;height:' + $props.height + ';position:relative;')
    }, null, 4)])]),
    _: 1
  });
}