import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2798274e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "QuestionCreateAndReviewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_divc = _resolveComponent("divc");
  const _component_QuestionCreateView = _resolveComponent("QuestionCreateView");
  const _component_el_col = _resolveComponent("el-col");
  const _component_ImportedQuestionList = _resolveComponent("ImportedQuestionList");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_divc, {
    class: "rightCenter"
  }, {
    default: _withCtx(() => [_createElementVNode("span", null, "已录入" + _toDisplayString($data.qList.length) + "题，单选题" + _toDisplayString($options.typeCount('SELECT')) + "道，多选题" + _toDisplayString($options.typeCount('MULTIPLE')) + "道，判断题" + _toDisplayString($options.typeCount('JUDGE')) + "道   ", 1), _createVNode(_component_el_button, {
      onClick: $options.resetQList
    }, {
      default: _withCtx(() => [_createTextVNode("重新录入")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      onClick: $options.submitQList
    }, {
      default: _withCtx(() => [_createTextVNode("保存")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }), _createVNode(_component_el_row, {
    gutter: 10
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 12
    }, {
      default: _withCtx(() => [_createVNode(_component_QuestionCreateView, {
        ref: "qCreator",
        style: {
          "width": "100%",
          "border": "1px lightgray dashed",
          "padding": "20px"
        },
        onSubmit: $options.createQuestion
      }, null, 8, ["onSubmit"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 12
    }, {
      default: _withCtx(() => [_createVNode(_component_ImportedQuestionList, {
        "q-list": $data.qList,
        onRemove: $options.remove,
        style: {
          "width": "100%",
          "border": "1px lightgray dashed",
          "padding": "20px",
          "height": "88vh",
          "overflow-y": "scroll"
        }
      }, null, 8, ["q-list", "onRemove"])]),
      _: 1
    })]),
    _: 1
  })]);
}