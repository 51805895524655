import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "TrainTopicSettingView",
  components: {
    BatterTableDataView
  },
  data() {
    return {
      enableMap: {},
      visibleMap: {},
      createVisible: false,
      initQuery: {}
    };
  },
  methods: {
    enableChange(id, val) {
      this.enableMap[id] = val;
      api.updateOrganizationEnableState(id, val);
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.enable;
        this.visibleMap[itm.id] = itm.visible;
      }
    }
  }
};