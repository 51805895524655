import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b683c270"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "color": "white"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.dialogVisible = $event),
    width: $props.width,
    onClose: $options.onDialogClose,
    "show-close": false
  }, {
    header: _withCtx(() => [_createVNode(_component_el_row, {
      style: {
        "background-color": "#005FF5"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 8
      }), _createVNode(_component_el_col, {
        span: 8,
        style: {
          "text-align": "center",
          "padding": "10px 0"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($props.title), 1)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 8,
        style: {
          "align-self": "center",
          "text-align": "right",
          "padding": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_svg_icon, {
          "icon-class": 'close',
          style: {
            "cursor": "pointer"
          },
          onClick: _cache[0] || (_cache[0] = $event => $data.dialogVisible = false)
        })]),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "dialog", {}, undefined, true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["modelValue", "width", "onClose"]);
}