import api from "@/api";
export default {
  name: "PostAudit",
  data() {
    return {
      more: true,
      user: {}
    };
  },
  methods: {
    init() {
      api.getNextAuditUser().then(ret => {
        if (!ret) {
          this.more = false;
          return;
        }
        this.user = ret;
      });
    },
    audit(pass) {
      api.auditUser({
        userId: this.user.id,
        state: pass ? 2 : 3
      }).then(() => {
        this.init();
      });
    }
  },
  mounted() {
    this.init();
  }
};