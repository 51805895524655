import BannerSettingView from "@/views/system/BannerSettingView";
import FriendLinkSettingView from "@/views/system/FriendLinkSettingView";
import IndexSpecialSubjectConfigView from "@/views/system/IndexSpecialSubjectConfigView";
export default {
  name: "MobileSettingView",
  components: {
    IndexSpecialSubjectConfigView,
    FriendLinkSettingView,
    BannerSettingView
  },
  data() {
    return {
      tab: 'banner'
    };
  },
  methods: {}
};