import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import DialogView from "@/components/DialogView";
import LocationPicker from "@/components/dashboard/LocationPicker";
import api from "@/api";
export default {
  name: "TeachListView",
  components: {
    LocationPicker,
    BatterTableDataView,
    DialogView
  },
  data() {
    return {
      createLatitude: 30.246552,
      createLongitude: 120.209882,
      editLatitude: null,
      editLongitude: null,
      editId: 0,
      editDataVersion: 0,
      createDataVersion: 0,
      initQuery: {},
      formOverWrite: {},
      editDialogVisible: false,
      createDialogVisible: false
    };
  },
  methods: {
    createTrain() {
      api.createTrain().then(ret => {
        this.$router.push('/edit/train/' + ret.id);
      });
    },
    onPlaceSelected(place) {
      let loc = place.location.split(',');
      if (this.createDialogVisible) {
        this.createLongitude = loc[0];
        this.createLatitude = loc[1];
        this.createDataVersion++;
      } else {
        this.editLongitude = loc[0];
        this.editLatitude = loc[1];
        this.editDataVersion++;
      }
      console.log('onPlaceSelected', place);
    },
    onPickerPosition(pos) {
      api.location2address(pos).then(addr => {
        this.formOverWrite = {
          'teachAddress': addr,
          'longitude': pos[0],
          'latitude': pos[1]
        };
      });
    },
    onDetailData(data) {
      this.$router.push('/teach/detail/' + data.id);
    },
    onEditData(data) {
      this.$router.push('/edit/train/' + data.id);
    },
    editPlan(data) {
      data.id = this.editId;
      if (!data.longitude) {
        data.longitude = this.editLongitude;
      }
      if (!data.latitude) {
        data.latitude = this.editLatitude;
      }
      api.editTeachPlan(data).then(() => {
        this.editDialogVisible = false;
        this.formOverWrite = {};
        this.initQuery = {
          refresh: new Date()
        };
      });
    },
    createPlan(data) {
      if (!data.longitude) {
        data.longitude = this.createLongitude;
      }
      if (!data.latitude) {
        data.latitude = this.createLatitude;
      }
      api.createTeachPlan(data).then(() => {
        this.createDialogVisible = false;
        this.formOverWrite = {};
        this.initQuery = {
          refresh: new Date()
        };
      });
    }
  }
};