import QuestionListItem from "@/components/question/QuestionListItem";
export default {
  name: "ImportedQuestionList",
  components: {
    QuestionListItem
  },
  props: {
    qList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    remove(qIndex) {
      this.$emit('remove', qIndex);
    }
  }
};