import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19f10826"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "QuestionSelectPanelContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_QuestionImportPageView = _resolveComponent("QuestionImportPageView");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_SelectorPageView = _resolveComponent("SelectorPageView");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
    modelValue: $data.activeName,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.activeName = $event),
    class: "demo-tabs"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "自主上传",
      name: "second"
    }, {
      default: _withCtx(() => [_createVNode(_component_QuestionImportPageView, {
        onSubmit: $options.saveManualIds
      }, null, 8, ["onSubmit"])]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "从题库选择",
      name: "first"
    }, {
      default: _withCtx(() => [_createVNode(_component_SelectorPageView, {
        onSave: $options.saveSelectedIds,
        onSaveAndBack: $options.saveAndBack,
        onResetSelect: _cache[0] || (_cache[0] = $event => _ctx.$emit('resetSelect')),
        "question-data": $props.questionData
      }, null, 8, ["onSave", "onSaveAndBack", "question-data"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}