export default {
  name: "QuestionListItem",
  props: {
    qIndex: {
      type: Number,
      default: 0
    },
    question: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      rightAnswers: null,
      options: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      qTypes: {
        'SELECT': '单选题',
        'MULTIPLE': '多选题',
        'JUDGE': '判断题'
      }
    };
  },
  methods: {
    answerChanged() {
      if (this.question.type == 'MULTIPLE') {
        this.$emit('updateAnswer', this.rightAnswers);
      } else {
        this.$emit('updateAnswer', [this.rightAnswers]);
      }
    }
  },
  mounted() {
    if (this.question.type == 'MULTIPLE') {
      this.rightAnswers = this.question.answers;
    } else {
      this.rightAnswers = this.question.answers[0];
    }
  }
};