import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4864f2ff"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "margin": "16px 0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'AnswerRecordCardContainer active': $props.active,
      'AnswerRecordCardContainer inactive': !$props.active
    }),
    style: {
      "font-size": "14px"
    }
  }, [_createElementVNode("span", null, _toDisplayString($options.convertTime($props.data.startTime)) + " 至 " + _toDisplayString($props.data.createdTime.substring(0, 16)), 1), _createElementVNode("span", _hoisted_1, "用时：" + _toDisplayString($options.convertTs($props.data.endTime - $props.data.startTime)), 1), _createElementVNode("span", null, "分数：" + _toDisplayString($props.data.score), 1)], 2);
}