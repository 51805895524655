import BatterTableDataView from "@/components/BatterTableDataView";
import DialogCustomView from "@/components/DialogCustomView";
import api from "@/api";
export default {
  name: "SpecialSubjectFileListView",
  components: {
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      visibleMap: {},
      previewVisible: false,
      previewSrc: null,
      initQuery: {
        subjectId: this.$route.params.subjectId
      },
      subjectId: this.$route.params.subjectId
    };
  },
  methods: {
    visibleChange(id, val) {
      this.visibleMap[id] = val;
      api.updateDataFileVisibleState(id, val);
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.visibleMap[itm.id] = itm.visible;
      }
    },
    onDetailData(data) {
      if (data.dataFileType != 'SPF') {
        this.previewSrc = data.fileUrl;
        this.previewVisible = true;
      } else {
        window.open(data.fileUrl, '_blank');
      }
    }
  }
};