import "core-js/modules/es.array.push.js";
import OssUploader from "@/components/OssUploader";
import api from "@/api";
import DialogView from "@/components/DialogView";
import LocationPicker from "@/components/dashboard/LocationPicker";
import QuestionRepoSelector from "@/components/teach/QuestionRepoSelector";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "BatterTrainEditorView",
  components: {
    QuestionRepoSelector,
    OssUploader,
    DialogView,
    LocationPicker,
    BatterTableDataView
  },
  data() {
    return {
      trainDataVersion: 0,
      questionInitQuery: {
        trainId: this.$route.params.planId
      },
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      selectRepoVisible: false,
      addressPickVisible: false,
      formOverWrite: {},
      editLatitude: null,
      editLongitude: null,
      editDataVersion: 0,
      planId: this.$route.params.planId,
      uploadIcon: 'https://cdn.waityou.online/b2b226aa-8f38-0399-b9b5-49899c43c61b.png',
      uploadedIcon: 'https://cdn.waityou.online/45ab69c8-a320-3545-fec5-0eaab8f0e4cb.png',
      plan: {
        clazz: {}
      },
      topics: [],
      selectedQuestionIds: []
    };
  },
  methods: {
    submitTrain() {
      api.submitTrain(this.planId).then(() => {
        this.$router.back();
      });
    },
    handleSelectionChange(change) {
      this.selectedQuestionIds = change.map(q => q.id);
    },
    removeQuestion() {
      api.removeQuestion({
        trainId: this.planId,
        qids: this.selectedQuestionIds
      }).then(() => {
        this.init();
      });
    },
    reset() {
      api.resetTrainQuestions(this.planId).then(() => {
        this.$refs.trainQuestionTable.fetchData();
      });
    },
    generateQuestions() {
      api.generateQuestionsByTrainId(this.planId).then(() => {
        this.$refs.trainQuestionTable.fetchData();
      });
    },
    countTotalScore(list) {
      if (!list) {
        return 0;
      }
      let s = 0;
      for (let r of list) {
        s += r.eachSingleScore * r.singleSize;
        s += r.eachMultipleScore * r.multipleSize;
        s += r.eachJudgeScore * r.judgeSize;
      }
      return s;
    },
    avgScore(list) {
      return 100.0 / this.countTotalQuestion(list);
    },
    countTotalQuestion(list) {
      if (!list) {
        return 0;
      }
      let s = 0;
      for (let r of list) {
        s += r.singleSize;
        s += r.multipleSize;
        s += r.judgeSize;
      }
      return s;
    },
    removeRepo(repoIndex) {
      this.plan.clazz.repoImportedConfigList.splice(repoIndex, 1);
      api.resetTrainQuestions(this.planId).then(() => {
        this.updatePlan();
      });
    },
    onRepoChange(repo) {
      for (let i in this.plan.clazz.repoImportedConfigList) {
        if (this.plan.clazz.repoImportedConfigList[i].repoId == repo.repoId) {
          this.plan.clazz.repoImportedConfigList[i] = Object.assign({}, repo);
          break;
        }
      }
      // api.resetTrainQuestions(this.planId)
      //     .then(() => {
      this.updatePlan();
      //     })
    },

    onSelectRepo(data) {
      api.getRepoDetail(data.repoId).then(ret => {
        let repoImportedConfigList = this.plan.clazz.repoImportedConfigList;
        if (repoImportedConfigList == null) {
          repoImportedConfigList = [];
        }
        repoImportedConfigList.push({
          repoId: ret.repo.id,
          repoName: ret.repo.name,
          singleSize: ret.SELECT,
          maxSingleSize: ret.SELECT,
          eachSingleScore: 5,
          multipleSize: ret.MULTIPLE,
          maxMultipleSize: ret.MULTIPLE,
          eachMultipleScore: 5,
          judgeSize: ret.JUDGE,
          maxJudgeSize: ret.JUDGE,
          eachJudgeScore: 5
        });
        this.plan.clazz.repoImportedConfigList = repoImportedConfigList;
        this.updatePlan();
      });
      this.selectRepoVisible = false;
    },
    onUploadedAgenda(file) {
      this.plan.clazz.agendaDocument = file;
      this.updatePlan();
    },
    onUploadedClassDocument(file) {
      this.plan.clazz.classDocument = file;
      this.updatePlan();
    },
    updatePlanAddress(data) {
      this.plan.clazz.longitude = data.longitude;
      this.plan.clazz.latitude = data.latitude;
      this.plan.clazz.teachAddress = data.teachAddress;
      this.updatePlan();
      this.addressPickVisible = false;
      this.editDataVersion++;
    },
    onPickerPosition(pos) {
      api.location2address(pos).then(addr => {
        this.formOverWrite = {
          'teachAddress': addr,
          'longitude': pos[0],
          'latitude': pos[1]
        };
      });
    },
    onPlaceSelected(place) {
      let loc = place.location.split(',');
      this.editLongitude = loc[0];
      this.editLatitude = loc[1];
      this.formOverWrite = {
        'longitude': loc[0],
        'latitude': loc[1]
      };
      this.editDataVersion++;
    },
    onImgUploaded(img) {
      this.plan.clazz.coverImage = img;
      this.updatePlan();
    },
    updatePlan() {
      api.updateTrainPlan(this.plan.clazz).then(() => {
        this.init();
      });
    },
    init() {
      api.getTeachClassInfo(this.planId).then(ret => {
        this.plan = ret;
        this.trainDataVersion++;
        this.$refs.trainQuestionTable.fetchData();
      });
    }
  },
  mounted() {
    this.init();
    api.getTrainTopics().then(ret => {
      this.topics = ret;
    });
  }
};