import SvgIcon from "@/components/SvgIcon";
import DashboardNumber from "@/components/dashboard/DashboardNumber";
import VerticalBarChart from "@/components/dashboard/VerticalBarChart";
// import PieChart from "@/components/dashboard/PieChart";
import api from "@/api";
import DynaQueryForm from "@/components/DynaQueryForm";
import DistrictMiniTable from "@/components/dashboard/minitable/DistrictMiniTable";
import CommunityMiniTable from "@/components/dashboard/minitable/CommunityMiniTable";
import StreetMiniTable from "@/components/dashboard/minitable/StreetMiniTable";
export default {
  name: "DashboardView",
  components: {
    StreetMiniTable,
    CommunityMiniTable,
    DistrictMiniTable,
    DynaQueryForm,
    VerticalBarChart,
    DashboardNumber,
    SvgIcon
  },
  data() {
    return {
      numbers: [],
      timeRange: null,
      regionName: '-',
      regionId: 0,
      monthStartTime: 0,
      mapLevel: 0,
      chart: {},
      regionCntChart: null,
      reportTable: null
    };
  },
  methods: {
    onQueryChange(q) {
      if (q.regionId) {
        this.regionId = q.regionId;
        this.init();
      }
    },
    init() {
      this.regionName = '-';
      this.numbers = [];
      this.regionCntChart = null;
      this.chart = {};
      api.getDashboardData({
        regionId: this.regionId,
        timeRange: this.timeRange
      }).then(ret => {
        this.regionName = ret.regionName;
        this.numbers = ret.numbers;
        this.chart = ret.chart;
        this.regionCntChart = ret.regionCntChart;
      });
      api.queryReportTableData({
        reportType: 0,
        adaptKeys: true,
        timeRange: this.timeRange
      }).then(ret => {
        this.reportTable = ret.list;
      });
    }
  },
  mounted() {
    this.init();
  }
};