import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "ExamResultView",
  components: {
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      detailVisible: false,
      detailTitle: '',
      detailUserId: null,
      examId: this.$route.params.examId,
      mode: this.$route.params.mode
    };
  },
  methods: {
    onDetailData(data) {
      this.$router.push(`/exam/result/detail/${data.examId}/${data.userId}/${this.mode}`);
      // this.detailUserId = data.userId;
      // this.detailTitle = data.orgName + '/' + data.userName;
      // this.detailVisible = true;
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },
    formatMilliseconds(milliseconds) {
      const seconds = Math.floor(milliseconds / 1000 % 60);
      const minutes = Math.floor(milliseconds / (1000 * 60) % 60);
      const hours = Math.floor(milliseconds / (1000 * 60 * 60) % 24);
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      return formattedTime;
    }
  }
};