import QuestionSelectPanel from "@/components/question/QuestionSelectPanel";
import api from "@/api";
export default {
  name: "QuestionSelector",
  components: {
    QuestionSelectPanel
  },
  data() {
    return {
      classId: this.$route.params.classId,
      selectedData: {
        selectedIds: [],
        judgeCnt: 0,
        selectCnt: 0,
        mSelectCnt: 0
      }
    };
  },
  methods: {
    resetSelect() {
      api.resetTrainQuestions(this.classId).then(() => {
        this.init();
      });
    },
    saveAndBack(qids) {
      api.updateClassQuestions({
        'classId': this.classId,
        'questionIds': qids,
        'saveAndBack': true
      }).then(() => {
        this.$router.back();
      });
    },
    //自主上传的题目
    saveManualIds(qids) {
      api.updateManualTrainQuestion({
        trainId: this.classId,
        questionIds: qids
      }).then(() => {
        this.$router.back();
      });
    },
    saveSelectedIds(qids) {
      api.updateClassQuestions({
        'classId': this.classId,
        'questionIds': qids,
        'saveAndBack': false
      }).then(() => {
        this.init();
      });
    },
    init() {
      api.getTeachClassInfo(this.classId).then(ret => {
        this.selectedData = {
          selectedIds: ret.selectedQuestionIds,
          judgeCnt: ret.judgeCnt,
          selectCnt: ret.selectCnt,
          mSelectCnt: ret.mSelectCnt
        };
      });
    }
  },
  mounted() {
    this.init();
  }
};