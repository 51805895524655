import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-14516167"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "QrCodeContainer"
};
const _hoisted_2 = {
  style: {
    "border": "#EEEEEE 1px solid"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_qrcode = _resolveComponent("qrcode");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_qrcode, {
    value: $options.encodedContent,
    size: 200
  }, null, 8, ["value"])])]);
}