import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6fa11a48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "FriendLinkSettingViewContainer"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    style: {
      "margin-top": "20px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "primaryBtn",
      icon: "plus",
      onClick: _cache[0] || (_cache[0] = $event => $data.createFriendLinkVisible = true)
    }, {
      default: _withCtx(() => [_createTextVNode("新增友情链接")]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "15",
      onOnFetchData: $options.onFetchData,
      initQuery: $data.initQuery
    }, {
      icon: _withCtx(data => [_createElementVNode("img", {
        src: data.rowData,
        style: {
          "width": "100%"
        }
      }, null, 8, _hoisted_2)]),
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        "active-color": "#0078D7",
        "model-value": $data.enableMap[data.rowId],
        onChange: v => $options.enableChange(data.rowId, v)
      }, null, 8, ["model-value", "onChange"])]),
      _: 1
    }, 8, ["onOnFetchData", "initQuery"])]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "data-id": 18,
    "visible-control": $data.createFriendLinkVisible,
    onClose: _cache[1] || (_cache[1] = $event => $data.createFriendLinkVisible = false),
    onSubmit: $options.createFriendLink
  }, null, 8, ["visible-control", "onSubmit"])], 64);
}