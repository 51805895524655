export default {
  name: "AnswerRecordCard",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    convertTime(timestamp) {
      // 创建一个Date对象
      var date = new Date(timestamp);
      var year = date.getFullYear(); // 年份
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份（加1后补零）
      var day = String(date.getDate()).padStart(2, '0'); // 日期（补零）
      var hours = String(date.getHours()).padStart(2, '0'); // 小时（补零）
      var minutes = String(date.getMinutes()).padStart(2, '0'); // 分钟（补零）
      // var seconds = String(date.getSeconds()).padStart(2, '0'); // 秒钟（补零）

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    convertTs(milliseconds) {
      var minutes = Math.floor(milliseconds % 3600000 / 60000); // 分钟
      var seconds = milliseconds % 3600000 % 60000 / 1000; // 秒
      return `${minutes}分${seconds.toFixed(0)}秒`;
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};