import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from "@/views/HomeView";
import DashboardView from "@/views/dashboard/DashboardView";
import BannerList from "@/views/banner/BannerList";
import HelpInfoSetting from "@/views/system/HelpInfoSetting";
import UserListView from "@/views/user/UserListView";
import PostView from "@/views/PostView";
import PostAudit from "@/views/audit/PostAudit";
import CommentAudit from "@/views/audit/CommentAudit";
import UserAudit from "@/views/audit/UserAudit";
import FeedbackView from "@/views/feedback/FeedbackView";
import TeachListView from "@/views/teach/TeachListView";
import TeachTrendsView from "@/views/teach/TeachTrendsView";
import CreateTeachTrendsView from "@/views/teach/CreateTeachTrendsView";
import StudyRepoView from "@/views/study/StudyRepoView";
import RepoImportView from "@/views/study/RepoImportView";
import QuestionManageView from "@/views/study/QuestionManageView";
import SpecialSubjectListView from "@/views/special/SpecialSubjectListView";
import CorrectionListView from "@/views/correct/CorrectionListView";
import ExamListView from "@/views/exam/ExamListView";
import ExamQuestionSelector from "@/views/exam/ExamQuestionSelector";
import TeachReport from "@/views/report/TeachReport";
import StudyReport from "@/views/report/StudyReport";
import ExamReport from "@/views/report/ExamReport";
import RoleListView from "@/views/user/RoleListView";
import RepoCategorySettingView from "@/views/system/RepoCategorySettingView";
import TrainTopicSettingView from "@/views/system/TrainTopicSettingView";
import ExamTypeSettingView from "@/views/system/ExamTypeSettingView";
import SettingLogView from "@/views/system/SettingLogView";
import MobileSettingView from "@/views/system/MobileSettingView";
import RegionSettingView from "@/views/system/RegionSettingView";
import OrganizationSettingView from "@/views/user/OrganizationSettingView";
import TeachDetailView from "@/views/teach/TeachDetailView";
import ClassQuestionSelector from "@/views/teach/ClassQuestionSelector";
import ExamEditorView from "@/views/exam/ExamEditorView";
import SpecialSubjectFileListView from "@/views/special/SpecialSubjectFileListView";
import BatterLoginView from "@/views/BatterLoginView";
import ExamineListView from "@/views/exam/ExamineListView";
import TestView from "@/views/TestView";
import ExamResultView from "@/views/exam/ExamResultView";
import MessagesView from "@/views/system/MessagesView";
import BatterTrainEditorView from "@/views/teach/BatterTrainEditorView";
import BatterExamEditorView from "@/views/exam/BatterExamEditorView";
import ExamStatisticView from "@/views/exam/ExamStatisticView";
import UserExamDetailView from "@/views/exam/UserExamDetailView";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        redirect: "/dashboard",
        children: [
            {
                path: "/dashboard",
                component: DashboardView
            },
            {
                path: '/bannerList',
                component: BannerList
            },
            {
                path: '/postList',
                component: PostView
            },
            {
                path: '/helpSetting',
                component: HelpInfoSetting
            },
            {
                path: '/users',
                component: UserListView
            }, {
                path: '/audit/post',
                component: PostAudit
            }, {
                path: '/audit/comment',
                component: CommentAudit
            }, {
                path: '/audit/id',
                component: UserAudit
            }, {
                path: '/feedback',
                component: FeedbackView
            }, {
                path: '/teach/list',
                component: TeachListView
            },{
                path: '/teach/detail/:id',
                component: TeachDetailView
            }, {
                path: '/teach/trends',
                component: TeachTrendsView
            }, {
                path: '/teach/trends/create/:id',
                component: CreateTeachTrendsView
            }, {
                path: '/edit/train/:planId',
                component: BatterTrainEditorView
            }, {
                path: '/study/repo',
                component: StudyRepoView
            }, {
                path: '/study/import/:repoId',
                component: RepoImportView
            },
            {
                path: '/question/manage/:repoId',
                component: QuestionManageView
            },
            {
                path: '/special/subject/list',
                component: SpecialSubjectListView
            },
            {
                path: '/special/subject/file/list/:subjectId',
                component: SpecialSubjectFileListView
            },
            {
                path: '/correct/list',
                component: CorrectionListView
            },
            {
                path: '/exam/create/:eid',
                component: BatterExamEditorView
            },
            {
                path: '/exam/statistic/:eid',
                component: ExamStatisticView
            },
            {
                path: '/exam/list',
                component: ExamListView
            },
            {
                path: '/examine/list',
                component: ExamineListView
            },
            {
                path: '/exam/question/selector/:examId',
                component: ExamQuestionSelector
            },
            {
                path: '/class/question/selector/:classId',
                component: ClassQuestionSelector
            },
            {
                path: '/report/teach',
                component: TeachReport
            },
            {
                path: '/report/study',
                component: StudyReport
            },
            {
                path: '/report/exam',
                component: ExamReport
            },
            {
                path: '/role/list',
                component: RoleListView
            },
            {
                path: '/setting/repo/category',
                component: RepoCategorySettingView
            },
            {
                path: '/setting/train/topic',
                component: TrainTopicSettingView
            },
            {
                path: '/setting/exam/type',
                component: ExamTypeSettingView
            },
            {
                path: '/setting/logs',
                component: SettingLogView
            },
            {
                path: '/setting/mobile',
                component: MobileSettingView
            },
            {
                path: '/setting/region',
                component: RegionSettingView
            },
            {
                path: '/org/list',
                component: OrganizationSettingView
            },
            {
                path: '/exam/editor/:examId',
                component: ExamEditorView
            },
            {
                path: '/exam/result/:examId/:mode',
                component: ExamResultView
            },
            {
                path: '/exam/result/detail/:examId/:userId/:mode',
                component: UserExamDetailView
            },
            {
                path: '/messages',
                component: MessagesView
            },
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: BatterLoginView,
        meta: {
            auth: false
        }
    },
    {
        path: '/test',
        name: 'test',
        component: TestView,
        meta: {
            auth: false
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
