import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "ExamListView",
  components: {
    BatterTableDataView
  },
  data() {
    return {};
  },
  methods: {
    onDetailData(data) {
      this.$router.push('/exam/result/' + data.id + '/0');
    },
    onEditData(data) {
      this.$router.push('/exam/create/' + data.id);
    },
    onCreateMore() {
      api.createEditingExam({
        examine: false
      }).then(ret => {
        this.$router.push('/exam/create/' + ret.id);
      });
      // this.$router.push('/question/selector/0')
    }
  }
};