import api from "@/api";
import { ElMessage } from 'element-plus';
import DialogView from "@/components/DialogView";
export default {
  name: "HelpInfoSetting",
  components: [DialogView],
  data() {
    return {
      helps: [],
      imgEditDialogVisible: false,
      imgEditDialogBindKey: null
    };
  },
  methods: {
    queryConfig() {
      api.getSystemConfig().then(ret => {
        this.helps = ret;
      });
    },
    updateImgConfig(data) {
      api.updateImageValue(data).then(() => {
        ElMessage.success('更新成功');
        this.imgEditDialogVisible = false;
        this.queryConfig();
      });
    },
    updateConfig(index) {
      api.updateSystemConfig(this.helps[index]).then(() => {
        ElMessage.success('更新成功');
        this.queryConfig();
      });
    }
  },
  mounted() {
    this.queryConfig();
  }
};