import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "ExamStatisticView",
  components: {
    BatterTableDataView
  },
  data() {
    return {
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      examId: this.$route.params.eid
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};