import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import DialogCustomView from "@/components/DialogCustomView";
import QrCode from "@/components/QrCode";
import api from "@/api";
export default {
  name: "ExamListView",
  components: {
    QrCode,
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      qrVisible: false,
      qrContent: '',
      examineCode: ''
    };
  },
  methods: {
    onDetailData(data) {
      this.$router.push('/exam/result/' + data.id + '/1');
    },
    showExamQrCode(id, code) {
      this.qrContent = '/#/examination/' + id + '?code=' + code;
      this.examineCode = code;
      this.qrVisible = true;
    },
    onEditData(data) {
      this.$router.push('/exam/create/' + data.id);
    },
    onCreateMore() {
      api.createEditingExam({
        examine: true
      }).then(ret => {
        this.$router.push('/exam/create/' + ret.id);
      });
      // this.$router.push('/exam/create/1')
      // this.$router.push('/question/selector/0?examine=true')
    }
  }
};