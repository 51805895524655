export default {
  name: "QuestionDetailCard",
  props: {
    classId: {
      type: Number,
      default: 0
    },
    editBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N']
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};