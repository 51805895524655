import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-49a30dd4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "BannerSettingViewContainer"
};
const _hoisted_2 = {
  key: 0,
  src: "https://cdn.waityou.online/d6a3afa9-903c-1585-85b3-f88ffbb3112f.png",
  style: {
    "width": "100%"
  }
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    style: {
      "margin-top": "20px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "primaryBtn",
      icon: "plus",
      onClick: _cache[0] || (_cache[0] = $event => $data.createBannerVisible = true)
    }, {
      default: _withCtx(() => [_createTextVNode("新增轮播图")]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "14",
      onOnFetchData: $options.onFetchData,
      initQuery: $data.initQuery
    }, {
      image: _withCtx(data => [data.data.bannerType === 'Lottie' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", {
        key: 1,
        src: data.rowData,
        style: {
          "width": "100%"
        }
      }, null, 8, _hoisted_3))]),
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        "active-color": "#0078D7",
        "model-value": $data.enableMap[data.rowId],
        onChange: v => $options.enableChange(data.rowId, v)
      }, null, 8, ["model-value", "onChange"])]),
      _: 1
    }, 8, ["onOnFetchData", "initQuery"])]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "data-id": 15,
    "visible-control": $data.createBannerVisible,
    onClose: _cache[1] || (_cache[1] = $event => $data.createBannerVisible = false),
    onSubmit: $options.createBanner
  }, null, 8, ["visible-control", "onSubmit"])], 64);
}