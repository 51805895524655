import SpecialSubjectItem from "@/components/special/SpecialSubjectItem";
import SpecialSubjectItemAdd from "@/components/special/SpecialSubjectItemAdd";
import DialogView from "@/components/DialogView";
import api from "@/api";
export default {
  name: "SpecialSubjectListView",
  components: {
    DialogView,
    SpecialSubjectItemAdd,
    SpecialSubjectItem
  },
  data() {
    return {
      createVisible: false,
      subjects: []
    };
  },
  methods: {
    onDeleteSp(id) {
      api.deleteSpecialSubject(id).then(() => this.list());
    },
    createSpecialSubject(data) {
      api.createSpecialSubject(data).then(() => {
        this.createVisible = false;
        this.list();
      });
    },
    list() {
      api.listSpecialSubjects({
        page: 0,
        pageSize: 100
      }).then(ret => {
        this.subjects = ret.list;
      });
    }
  },
  mounted() {
    this.list();
  }
};