import SvgIcon from "@/components/SvgIcon";
import api from "@/api";
export default {
  name: "QuestionCreateView",
  components: {
    SvgIcon
  },
  props: {
    updateId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    updateId() {
      this.initEditValues();
    }
  },
  mounted() {
    if (this.updateId > 0) {
      this.initEditValues();
    }
  },
  data() {
    return {
      id: 0,
      btnName: '提交试题',
      hardType: '0',
      questionType: '0',
      questionContent: null,
      questionAnalysis: null,
      options: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      questionOption: ['', '', '', ''],
      answers: [],
      answer: '',
      source: '-'
    };
  },
  methods: {
    reset() {
      console.log('重置!');
      this.id = 0;
      this.btnName = '提交试题';
      this.hardType = '0';
      this.questionType = '0';
      this.questionContent = null;
      this.questionAnalysis = null;
      this.options = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'];
      this.questionOption = ['', '', '', ''];
      this.answers = [];
      this.answer = [];
      this.source = '-';
    },
    initEditValues() {
      let hardTypes = {
        'VERY_EASY': '0',
        'EASY': '1',
        'MID': '2',
        'LITTLE_HARD': '3',
        'HARD': '4'
      };
      let qTypes = {
        'SELECT': '0',
        'MULTIPLE': '1',
        'JUDGE': '2'
      };
      api.getQuestionDetail(this.updateId).then(ret => {
        this.id = ret.id;
        this.hardType = hardTypes[ret.difficultyType];
        this.questionType = qTypes[ret.type];
        this.questionContent = ret.description;
        this.questionAnalysis = ret.analysis;
        this.questionOption = JSON.parse(ret.options);
        this.answers = JSON.parse(ret.answers);
        this.answer = JSON.parse(ret.answers)[0];
        this.source = ret.source;
        this.btnName = '更新试题';
        console.log('ret.options.split', ret.options.split(','));
      });
    },
    submit() {
      let judgeOptions = ['正确', '错误'];
      this.$emit('submit', {
        id: this.id,
        hardType: this.hardType,
        questionType: this.questionType,
        questionContent: this.questionContent,
        questionAnalysis: this.questionAnalysis,
        options: this.options,
        questionOption: this.questionType == 2 ? judgeOptions : this.questionOption,
        answers: this.answers,
        answer: this.answer,
        source: this.source
      });
    }
  }
};