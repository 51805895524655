import DialogCustomView from "@/components/DialogCustomView";
import BatterTableDataView from "@/components/BatterTableDataView";
import QuestionCreateView from "@/components/question/QuestionCreateView";
import api from "@/api";
import DialogView from "@/components/DialogView";
export default {
  name: "CorrectionListView",
  components: {
    DialogView,
    QuestionCreateView,
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      editVisible: false,
      updateQid: 0,
      dataId: 0,
      visible: false
    };
  },
  methods: {
    passCorrect(id) {
      api.updateCorrection({
        state: 2,
        id: id
      }).then(() => {
        this.$refs.correctionTable.fetchData();
      });
    },
    updateQuestion(data) {
      api.updateQuestion(data).then(() => {
        this.editVisible = false;
        this.$refs.correctionTable.fetchData();
      });
    },
    editQuestion(qid) {
      this.updateQid = qid;
      this.editVisible = true;
    }
  }
};