import "core-js/modules/es.array.push.js";
import ImportedQuestionList from "@/components/question/ImportedQuestionList";
import QuestionCreateView from "@/components/question/QuestionCreateView";
import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "QuestionCreateAndReview",
  components: {
    ImportedQuestionList,
    QuestionCreateView
  },
  data() {
    return {
      qList: [],
      removing: []
    };
  },
  methods: {
    submitQList() {
      let qids = [];
      for (let q of this.qList) {
        qids.push(q.id);
      }
      this.$emit('submit', qids);
    },
    remove(idx) {
      this.removing.push(this.qList[idx]);
      this.qList.splice(idx, 1);
    },
    createQuestion(data) {
      api.createQuestion(data).then(ret => {
        ret.options = JSON.parse(ret.options);
        ret.answers = JSON.parse(ret.answers);
        this.qList.push(ret);
        this.$refs.qCreator.reset();
        ElMessage.success('成功新增1题');
      });
    },
    typeCount(type) {
      let cnt = 0;
      for (let q of this.qList) {
        if (q.type == type) {
          cnt++;
        }
      }
      return cnt;
    },
    resetQList() {
      this.qList = [];
      this.$refs.qCreator.reset();
    }
  }
};