import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-577b0680"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "baseFont"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createBlock(_component_el_row, {
    class: "dashboardNumberItem"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($props.data.title), 1), _createVNode(_component_el_row, {
      class: "dashboardNumberContent"
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass(`numberWrap ${$props.data.iconClass}`)
      }, [_createElementVNode("img", {
        src: $data.icons[$props.data.iconClass]
      }, null, 8, _hoisted_2)], 2), _createElementVNode("span", null, _toDisplayString($props.data.number), 1)]),
      _: 1
    })]),
    _: 1
  });
}