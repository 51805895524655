import VueQrcode from 'vue-qrcode';
export default {
  name: "QrCode",
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    encodedContent() {
      return this.content;
    }
  },
  components: {
    qrcode: VueQrcode
  }
};