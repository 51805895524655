import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "SelectorPageView",
  components: {
    BatterTableDataView
  },
  props: {
    questionData: {
      type: Object,
      default: () => {
        return {
          selectedIds: [],
          judgeCnt: 0,
          selectCnt: 0,
          mSelectCnt: 0
        };
      }
    }
  },
  watch: {
    questionData() {
      this.init();
    }
  },
  data() {
    return {
      repoType: '0',
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      qTypes: {},
      currentRepoId: 0,
      selectedAll: {},
      selectCnt: 0,
      mSelectCnt: 0,
      judgeCnt: 0,
      currentQuestionIdList: {},
      repoListInitQuery: {},
      questionListInitQuery: {},
      existQuestionListInitQuery: {
        qids: []
      },
      q2repoMap: {},
      repoSelectCnt: {},
      existSelectedIds: []
    };
  },
  methods: {
    onRepoTypeChange() {
      this.repoListInitQuery = {
        repoType: this.repoType
      };
    },
    onSelectionChange(selected, unSelected) {
      let changed = false;
      let newQid = Object.assign(this.questionData.selectedIds);
      for (let s of selected) {
        if (newQid.indexOf(s.id) < 0) {
          newQid.push(s.id);
          changed = true;
        }
      }
      newQid = newQid.filter(qid => {
        if (unSelected.find(e => e.id == qid) == null) {
          changed = true;
          return true;
        }
        return false;
      });
      if (changed) {
        this.$emit('save', newQid);
      }
    },
    countRepoSelectCnt() {
      this.repoSelectCnt = {};
      for (let q of this.questionData.selectedIds) {
        let repoId = this.q2repoMap[q];
        let old = this.repoSelectCnt[repoId] ? this.repoSelectCnt[repoId] : 0;
        this.repoSelectCnt[repoId] = old + 1;
      }
    },
    doSelectedAll(selected, repoId) {
      this.selectedAll[repoId] = selected;
      api.getQuestionList(repoId).then(list => {
        for (let q of list.list) {
          this.q2repoMap[q.id] = repoId;
        }
        this.reCount();
      });
    },
    onFetchRepoList(repoList) {
      for (let q of repoList.list) {
        this.selectedAll[q.id] = false;
      }
    },
    onFetchQuestionList(qList) {
      for (let q of qList.list) {
        this.q2repoMap[q.id] = this.currentRepoId;
        this.qTypes[q.id] = q.type;
      }
      this.$refs.repoQuestionTable.toggleRowSelection(param => {
        return this.questionData.selectedIds.indexOf(param.id) >= 0;
      });
    },
    onExistSelectionChange(selected) {
      this.existSelectedIds = selected.map(s => s.id);
    },
    onFetchExistQuestionList() {},
    onRowSelectChange(row) {
      if (!row) {
        return;
      }
      this.currentRepoId = row.id;
      this.questionListInitQuery = {
        repoId: row.id
      };
    },
    saveQuestionIds() {
      this.$emit('saveAndBack', this.questionData.selectedIds);
    },
    deleteQuestionIds() {
      let update = this.questionData.selectedIds.filter(s => this.existSelectedIds.indexOf(s) < 0);
      this.$emit('save', update);
    },
    onResetSelect() {
      this.$emit('resetSelect');
      this.$refs.repoQuestionTable.clearSelection();
    },
    reCount() {
      this.mSelectCnt = 0;
      this.selectCnt = 0;
      this.judgeCnt = 0;
      for (let k of this.questionData.selectedIds) {
        let type = this.qTypes[k];
        if (type === 'SELECT') {
          this.selectCnt++;
        } else if (type === 'JUDGE') {
          this.judgeCnt++;
        } else {
          this.mSelectCnt++;
        }
      }
      this.existQuestionListInitQuery = {
        qids: this.questionData.selectedIds
      };
      this.countRepoSelectCnt();
    },
    init() {
      this.judgeCnt = this.questionData.judgeCnt;
      this.selectCnt = this.questionData.selectCnt;
      this.mSelectCnt = this.questionData.mSelectCnt;
      this.reCount();
      api.queryRepoMap({
        qids: this.questionData.selectedIds
      }).then(map => {
        this.q2repoMap = map;
        this.countRepoSelectCnt();
      });
      this.onRepoTypeChange();
    }
  },
  mounted() {
    this.init();
  }
};