import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-178cb646"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ImportedQuestionListContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_QuestionListItem = _resolveComponent("QuestionListItem");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.qList, (q, i) => {
    return _openBlock(), _createBlock(_component_QuestionListItem, {
      onDelete: $options.remove,
      question: q,
      "q-index": i,
      key: i
    }, null, 8, ["onDelete", "question", "q-index"]);
  }), 128))]);
}