import "core-js/modules/es.array.push.js";
import ReportTableView from "@/components/report/ReportTableView";
import api from "@/api";
import { ElLoading } from "element-plus";
export default {
  name: "TeachReport",
  components: {
    ReportTableView
  },
  data() {
    return {
      filterVisible: false,
      editVisible: false,
      timeRange: null,
      structure: [],
      checkedKeys: null,
      tableKeys: [],
      firstDay: null,
      lastDay: null
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onCheckedChange(e, t) {
      this.tableKeys = t.checkedKeys.concat(t.halfCheckedKeys);
    },
    onCreateMore() {
      this.editVisible = true;
    },
    exportExcel() {
      let loading = ElLoading.service({
        lock: true,
        text: '正在导出',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.exportReportTable({
        reportType: this.reportType,
        keys: this.tableKeys,
        timeRange: this.timeRange
      }).then(ret => {
        window.open(ret, '_blank');
      }).finally(() => {
        loading.close();
      });
    },
    init() {
      api.getTableStructure().then(ret => {
        this.structure = ret;
        this.checkedKeys = this.getAllKeys(ret);
        this.tableKeys = Object.assign(this.checkedKeys);
      });
    },
    getAllKeys(tree) {
      if (!tree) {
        return [];
      }
      let keys = [];
      for (let t of tree) {
        keys.push(t.id);
        for (let sid of this.getAllKeys(t.children)) {
          keys.push(sid);
        }
      }
      return keys;
    },
    initDate() {
      var today = new Date(); // 获取当前日期
      var year = today.getFullYear(); // 获取当前年份
      var month = today.getMonth(); // 获取当前月份（注意：月份从0开始，所以需要加1）

      var firstDay = new Date(year, month, 2); // 设置为当前月份的第一天
      var lastDay = new Date(year, month + 1, 1); // 设置为当前月份的下一个月的第0天，即当前月份的最后一天

      // 将日期格式化为 "YYYY-MM-DD"
      this.firstDay = firstDay.toISOString().slice(0, 10);
      this.lastDay = lastDay.toISOString().slice(0, 10);
    }
  },
  mounted() {
    this.init();
    this.initDate();
  }
};