import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "TrainTopicSettingView",
  components: {
    BatterTableDataView
  },
  data() {
    return {
      enableMap: {},
      visibleMap: {},
      createVisible: false,
      initQuery: {}
    };
  },
  methods: {}
};