import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19d5c949"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "DistrictMiniTableContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DashboardNumberTable = _resolveComponent("DashboardNumberTable");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
    class: "demo-tabs",
    modelValue: $data.activeTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.activeTab = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "村（社区）干部",
      name: "first"
    }, {
      default: _withCtx(() => [_createVNode(_component_DashboardNumberTable, {
        data: $props.reportTable,
        mapping: {
          unitNumberKey: '村（社区）,村（社区）干部人员培训覆盖情况,单位个数',
          actualNumberKey: '村（社区）,村（社区）干部人员培训覆盖情况,实际参训个数',
          unitPeopleNumberKey: '村（社区）,村（社区）干部人员培训覆盖情况,应培训人数',
          actualPeopleNumberKey: '村（社区）,村（社区）干部人员培训覆盖情况,实际参训人数'
        }
      }, null, 8, ["data"])]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "网格员",
      name: "second"
    }, {
      default: _withCtx(() => [_createVNode(_component_DashboardNumberTable, {
        data: $props.reportTable,
        mapping: {
          unitNumberKey: '村（社区）,网格员人员培训覆盖情况,单位个数',
          actualNumberKey: '村（社区）,网格员人员培训覆盖情况,实际参训个数',
          unitPeopleNumberKey: '村（社区）,网格员人员培训覆盖情况,应培训人数',
          actualPeopleNumberKey: '村（社区）,网格员人员培训覆盖情况,实际参训人数'
        }
      }, null, 8, ["data"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}