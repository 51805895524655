import DialogView from "@/components/DialogView";
import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "RepoCategorySettingView",
  components: {
    DialogView,
    BatterTableDataView
  },
  data() {
    return {
      enableMap: {},
      visibleMap: {},
      createVisible: false,
      initQuery: {}
    };
  },
  methods: {
    enableChange(id, val) {
      api.updateRepoCategoryEnableState(id, val).then(() => {
        this.enableMap[id] = val;
      });
    },
    visibleChange(id, val) {
      api.updateRepoCategoryVisibleState(id, val).then(() => {
        this.visibleMap[id] = val;
      });
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.enable;
        this.visibleMap[itm.id] = itm.visible;
      }
    },
    create(data) {
      api.createRepoCategory(data).then(() => {
        this.createVisible = false;
        this.initQuery = {
          'fresh': new Date()
        };
      });
    }
  }
};