import api from "@/api";
import { ElMessage } from "element-plus";
import DialogCustomView from "@/components/DialogCustomView";
import FixSignInView from "@/views/teach/FixSignInView";
import QuestionDetailCard from "@/components/teach/QuestionDetailCard";
import QuestionRepoSelector from "@/components/teach/QuestionRepoSelector";
import TrainSignedUserCard from "@/components/teach/TrainSignedUserCard";
import QrCode from "@/components/QrCode";
import OssUploader from "@/components/OssUploader";
export default {
  name: "TeachDetailView",
  components: {
    OssUploader,
    QrCode,
    TrainSignedUserCard,
    FixSignInView,
    DialogCustomView,
    QuestionDetailCard,
    QuestionRepoSelector
  },
  data() {
    return {
      cardTab: 0,
      signInQrVisible: false,
      editQuestionBtnVisible: true,
      fixSignInVisible: false,
      createDocVisible: false,
      createVideoVisible: false,
      editVideoVisible: false,
      editDocVisible: false,
      videoEditId: 0,
      docEditId: 0,
      classId: this.$route.params.id,
      classData: {
        clazz: {},
        chapters: [],
        signInCnt: 0,
        signInOrgCnt: 0,
        practiceCnt: 0,
        qualifiedCnt: 0
      },
      videoList: [],
      docList: [],
      updates: [],
      evaluates: []
    };
  },
  computed: {
    optionSingleHeight() {
      return {
        singleHeight: 26
      };
    }
  },
  methods: {
    deleteSignInPaper() {
      api.deleteSignInPaper(this.classId).then(() => {
        this.initInfo();
      });
    },
    onSignPaperUploaded(file) {
      api.updateSignPaper({
        planId: this.classId,
        'file': file
      }).then(() => {
        this.initInfo();
      });
    },
    avgScore(list) {
      return 100.0 / this.countTotalQuestion(list);
    },
    countTotalQuestion(list) {
      if (!list) {
        return 0;
      }
      let s = 0;
      for (let r of list) {
        s += r.singleSize;
        s += r.multipleSize;
        s += r.judgeSize;
      }
      return s;
    },
    fixSignIn() {
      this.fixSignInVisible = true;
    },
    deleteVideo(id) {
      api.deleteFile(id).then(() => {
        this.loadVideos();
      });
    },
    deleteDoc(id) {
      api.deleteFile(id).then(() => {
        this.loadDocs();
      });
    },
    createVideo(data) {
      data['classId'] = this.classId;
      api.createClassVideo(data).then(() => {
        this.createVideoVisible = false;
        this.loadVideos();
      });
    },
    createDoc(data) {
      data['classId'] = this.classId;
      api.createClassDoc(data).then(() => {
        this.createDocVisible = false;
        this.loadDocs();
      });
    },
    updateVideo(data) {
      data['id'] = this.videoEditId;
      api.updateClassVideo(data).then(() => {
        this.editVideoVisible = false;
        this.loadVideos();
      });
    },
    updateDoc(data) {
      data['id'] = this.docEditId;
      api.updateClassDoc(data).then(() => {
        this.editDocVisible = false;
        this.loadDocs();
      });
    },
    loadDocs() {
      api.getFileList({
        'refId': this.classId,
        'type': 2,
        'pageSize': 200
      }).then(list => {
        this.docList = list;
      });
    },
    loadVideos() {
      api.getFileList({
        'refId': this.classId,
        'type': 1,
        'pageSize': 200
      }).then(list => {
        this.videoList = list;
      });
    },
    onUploadedVideo(url) {
      console.log('视频已上传:', url);
      api.updateTeachVideo({
        'classId': this.classId,
        'video': url
      }).then(() => {
        this.classData.clazz['classVideo'] = url;
        ElMessage.success('上传成功');
      });
    },
    onUploadedDocument(url) {
      console.log('课件已上传:', url);
      api.updateTeachDocument({
        'classId': this.classId,
        'document': url
      }).then(() => {
        this.classData.clazz['classDocument'] = url;
        ElMessage.success('上传成功');
      });
    },
    saveDescription() {
      api.updateTeachDescription({
        'classId': this.classId,
        'description': this.classData.clazz.description
      }).then(() => {
        ElMessage.success('保存成功');
      });
    },
    resetSelectedQuestions() {
      api.updateClassQuestions({
        'classId': this.classId,
        'questionIds': []
      }).then(() => {
        this.initInfo();
      });
    },
    loadEvaluate() {
      api.getClassEvaluate(this.classId).then(list => {
        this.evaluates = list;
      });
    },
    loadUpdates() {
      api.getClassUpdates(this.classId).then(list => {
        this.updates = list;
      });
    },
    initInfo() {
      api.getTeachClassInfo(this.classId).then(ret => {
        this.classData = ret;
        if (ret.clazz.teachTime < new Date().getTime()) {
          this.cardTab = 1;
          this.editQuestionBtnVisible = false;
        }
      });
      this.loadVideos();
      this.loadDocs();
      this.loadEvaluate();
      this.loadUpdates();
    }
  },
  mounted() {
    this.initInfo();
  }
};