import "core-js/modules/es.array.push.js";
import api from "@/api";
import DialogView from "@/components/DialogView";
import BatterTableDataView from "@/components/BatterTableDataView";
import QuestionRepoSelector from "@/components/teach/QuestionRepoSelector";
import { ElMessage } from "element-plus";
export default {
  name: "BatterExamEditorView",
  components: {
    DialogView,
    BatterTableDataView,
    QuestionRepoSelector
  },
  data() {
    return {
      questionInitQuery: {
        examId: this.$route.params.eid
      },
      //type = 0 考试、type=1考核
      type: 0,
      exam: {},
      selectRepoVisible: false,
      examId: this.$route.params.eid,
      examTypes: [],
      categories: [],
      data: {
        startTimeStr: null,
        endTimeStr: null,
        title: null,
        examTypeId: null,
        categoryId: null,
        startTime: 0,
        endTime: 0,
        regionId: 0,
        timeLimit: 60,
        repeatExam: false,
        qualified: 60,
        selectScore: 5,
        mselectScore: 5,
        judgeScore: 5,
        forever: false,
        examine: this.$route.query.examine,
        backgroundColorId: 0
      },
      selectedQuestionIds: []
    };
  },
  methods: {
    changeEnableDownloadRecord() {
      api.changeEnableDownloadRecord({
        examId: this.examId
      }).then(() => {
        this.init();
      });
    },
    submitExam() {
      api.submitExam({
        examId: this.examId
      }).then(() => {
        this.$router.back();
      });
    },
    removeQuestion() {
      api.removeExamQuestion({
        examId: this.examId,
        qids: this.selectedQuestionIds
      }).then(() => {
        this.init();
      });
    },
    reset() {
      api.resetExamQuestions(this.examId).then(() => {
        this.$refs.examQuestionTable.fetchData();
      });
    },
    generateQuestions() {
      api.generateQuestionsByExamId(this.examId).then(() => {
        this.$refs.examQuestionTable.fetchData();
      });
    },
    removeRepo(repoIndex) {
      this.exam.repoImportedConfigList.splice(repoIndex, 1);
      api.resetExamQuestions(this.examId).then(() => {
        this.updateExam();
      });
    },
    onRepoChange(repo) {
      for (let i in this.exam.repoImportedConfigList) {
        if (this.exam.repoImportedConfigList[i].repoId == repo.repoId) {
          this.exam.repoImportedConfigList[i] = Object.assign({}, repo);
          break;
        }
      }
      this.updateExam();
    },
    handleSelectionChange(change) {
      this.selectedQuestionIds = change.map(q => q.id);
    },
    onSelectRepo(data) {
      api.getRepoDetail(data.repoId).then(ret => {
        let repoImportedConfigList = this.exam.repoImportedConfigList;
        if (repoImportedConfigList == null) {
          repoImportedConfigList = [];
        }
        repoImportedConfigList.push({
          repoId: ret.repo.id,
          repoName: ret.repo.name,
          singleSize: ret.SELECT,
          maxSingleSize: ret.SELECT,
          eachSingleScore: 5,
          multipleSize: ret.MULTIPLE,
          maxMultipleSize: ret.MULTIPLE,
          eachMultipleScore: 5,
          judgeSize: ret.JUDGE,
          maxJudgeSize: ret.JUDGE,
          eachJudgeScore: 5
        });
        this.exam.repoImportedConfigList = repoImportedConfigList;
        this.updateExam();
      });
      this.selectRepoVisible = false;
    },
    isDateDisabled(d) {
      let now = new Date();
      return d.getTime() < now.getTime();
    },
    updateExam() {
      api.updateExam(this.exam).then(() => {
        this.init();
      });
    },
    countTotalScore(list) {
      if (!list) {
        return 0;
      }
      let s = 0;
      for (let r of list) {
        s += r.eachSingleScore * r.singleSize;
        s += r.eachMultipleScore * r.multipleSize;
        s += r.eachJudgeScore * r.judgeSize;
      }
      return s;
    },
    avgScore(list) {
      return 100.0 / this.countTotalQuestion(list);
    },
    countTotalQuestion(list) {
      if (!list) {
        return 0;
      }
      let s = 0;
      for (let r of list) {
        s += r.singleSize;
        s += r.multipleSize;
        s += r.judgeSize;
      }
      return s;
    },
    init() {
      api.getExamById(this.examId).then(ret => {
        this.exam = ret;
        this.type = ret.examine ? 1 : 0;
        this.exam.forever = ret?.endTime < 1;
        if (!ret.editing) {
          ElMessage.warning('由于考核/考试已经开始，您处于仅查看模式无法修改');
        }
      });
      api.getRepoCategories().then(ret => {
        this.categories = ret;
      });
      api.getExamTypes().then(ret => {
        this.examTypes = ret;
      });
      this.$refs.examQuestionTable.fetchData();
    }
  },
  mounted() {
    this.init();
  }
};