import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "RegionSettingView",
  components: {
    BatterTableDataView
  },
  data() {
    return {
      initQuery: {},
      regions: [],
      treeProps: {
        class: 'stationList',
        isLeaf: 'leaf'
      }
    };
  },
  methods: {
    loadRegion(node, callback) {
      console.log('node.id', node, node.data.id);
      api.getRegionList(node.data.id ? node.data.id : 1, 1).then(ret => {
        callback(ret);
      });
    },
    allowDropCheck(draggingNode, dropNode, type) {
      // 禁止叶子节点成为其他节点的子节点
      if (type === 'inner') {
        return false;
      }
      // 禁止拖动节点被拖出其父节点
      if (dropNode.parent != draggingNode.parent) {
        return false;
      }
      // 禁止节点被拖到其子孙节点下
      if (dropNode.descendants && dropNode.descendants.indexOf(draggingNode) !== -1) {
        return false;
      }
      // 其他情况允许放置
      return true;
    },
    onNodeClick(node) {
      if (node.leaf) {
        return;
      }
      this.initQuery = {
        'superId': node.id
      };
    },
    afterDrop(before, after) {
      let orderIndexes = {};
      let orderIndex = 0;
      for (let e of after.parent.childNodes) {
        orderIndexes[e.data.id] = orderIndex;
        orderIndex++;
      }
      // api.updateRegionOrder(orderIndexes);
    }
  },

  mounted() {}
};