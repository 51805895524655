import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "FixSignInView",
  components: {
    BatterTableDataView
  },
  props: {
    classId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    fixSignIn(userId) {
      api.fixSignIn(this.classId, userId).then(() => {
        this.$refs.fixSignTable.fetchData();
        this.$emit('fixSignIn');
      });
    }
  }
};