export default {
  name: "DashboardNumber",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: '本月培训次数',
          number: 20,
          iconClass: 'yellow'
        };
      }
    }
  },
  data() {
    return {
      icons: {
        'yellow': 'https://cdn.waityou.online/25343262-1f68-9a3f-889d-29d1c88f8bda.png',
        'green': 'https://cdn.waityou.online/65cfb406-1d6e-d9ff-d571-591e2206a3c5.png',
        'blue': 'https://cdn.waityou.online/ff3bd564-7149-bded-777c-d5c5a28cee8c.png',
        'purple': 'https://cdn.waityou.online/913f59c2-0d81-3428-350e-8f293965df64.png'
      }
    };
  }
};