import "core-js/modules/es.array.push.js";
import { ElMessage } from "element-plus";
import api from "@/api";
export default {
  name: "ExamEditorView",
  data() {
    return {
      examId: this.$route.params.examId,
      selectedQuestionIds: [],
      examTypes: [],
      categories: [],
      data: {
        title: null,
        examTypeId: null,
        categoryId: null,
        startTime: 0,
        endTime: 0,
        regionId: 0,
        timeLimit: 60,
        repeatExam: false,
        qualified: 60,
        selectScore: 5,
        mselectScore: 5,
        judgeScore: 5,
        forever: false,
        examine: this.$route.query.examine,
        backgroundColorId: 0
      },
      selectCnt: 0,
      mSelectCnt: 0,
      judgeCnt: 0,
      sumScore: 0
    };
  },
  methods: {
    isDateDisabled(d) {
      let now = new Date();
      return d.getTime() < now.getTime();
    },
    createExam() {
      if (!this.data['startTime']) {
        ElMessage.error('请选择开始时间');
        return;
      }
      if (!this.data.forever) {
        if (!this.data['endTime']) {
          ElMessage.error('请选择结束时间');
          return;
        }
      }
      if (this.sumScore < this.data.qualified) {
        ElMessage.error('合格分数不能大于总分!');
        return;
      }
      if (this.examId > 0) {
        this.data['examId'] = this.examId;
      }
      let d = {};
      for (let key of Object.keys(this.data)) {
        d[key] = this.data[key];
      }
      d['endTime'] = this.data.forever ? 0 : d['endTime'].getTime();
      d['startTime'] = d['startTime'].getTime();
      d['questionIds'] = this.selectedQuestionIds;
      api.createExamByQuestionIds(d).then(() => {
        if (this.data.examine) {
          this.$router.replace('/examine/list');
        } else {
          this.$router.replace('/exam/list');
        }
      });
    },
    culSumScore() {
      this.sumScore = this.selectCnt * this.data.selectScore + this.mSelectCnt * this.data.mselectScore + this.judgeCnt * this.data.judgeScore;
    },
    initPage() {
      if (this.examId > 0) {
        api.getExamById(this.examId).then(ret => {
          this.data.title = ret.title;
          this.data.examTypeId = ret.examTypeId;
          this.data.categoryId = ret.categoryId;
          this.data.startTime = new Date(ret.startTimeStr);
          if (ret.endTime < 1) {
            this.data.forever = true;
          } else {
            this.data.endTime = ret.endTimeStr;
          }
          this.data.regionId = ret.regionId;
          this.data.timeLimit = ret.timeLimit;
          this.data.repeatExam = ret.repeatExam;
          this.data.qualified = ret.qualified;
          this.data.selectScore = ret.selectScore;
          this.data.mselectScore = ret.mselectScore;
          this.data.judgeScore = ret.judgeScore;
          this.data.examine = ret.examine;
          this.data.backgroundColorId = ret.backgroundColorId;
        });
      }
      api.getRepoCategories().then(ret => {
        this.categories = ret;
      });
      api.getExamTypes().then(ret => {
        this.examTypes = ret;
      });
      api.getQuestionsByIds({
        'ids': this.selectedQuestionIds
      }).then(list => {
        this.selectCnt = 0;
        this.mSelectCnt = 0;
        this.judgeCnt = 0;
        for (let q of list) {
          if (q.type == 'SELECT') {
            this.selectCnt += 1;
          } else if (q.type == 'JUDGE') {
            this.judgeCnt += 1;
          } else {
            this.mSelectCnt += 1;
          }
        }
        this.culSumScore();
      });
    }
  },
  mounted() {
    let qids = this.$route.query.qids;
    for (let qid of qids.split(',')) {
      this.selectedQuestionIds.push(parseInt(qid));
    }
    this.initPage();
    ElMessage.success('已选择' + this.selectedQuestionIds.length + '道题目');
  }
};