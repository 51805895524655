import api from "@/api";
export default {
  name: "PostAudit",
  data() {
    return {
      more: true,
      post: {},
      images: []
    };
  },
  methods: {
    init() {
      api.getNextAuditPost().then(ret => {
        if (!ret) {
          this.more = false;
          return;
        }
        this.post = ret.post;
        this.images = ret.images;
      });
    },
    audit(pass) {
      api.auditPost({
        postId: this.post.id,
        state: pass ? 2 : 3
      }).then(() => {
        this.init();
      });
    }
  },
  mounted() {
    this.init();
  }
};