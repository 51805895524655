import '@wangeditor/editor/dist/css/style.css'; // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { ElMessage } from "element-plus";
import { uploadFile } from "@/api/uploader";
import OssUploader from "@/components/OssUploader";
import api from "@/api";
import MobileFrame from "@/components/MobileFrame";
export default {
  name: "CreateTeachTrendsView",
  components: {
    MobileFrame,
    OssUploader,
    Editor,
    Toolbar
  },
  data() {
    return {
      postId: this.$route.params.id,
      coverImage: null,
      title: '',
      mode: 'default',
      imageUrl: null,
      editor: null,
      valueHtml: '',
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          'uploadImage': {
            async customUpload(file, insertFn) {
              ElMessage.info('正在上传,请稍后');
              uploadFile(file, url => {
                insertFn(url, '', '');
              });
            }
          },
          'uploadVideo': {
            async customUpload(file, insertFn) {
              ElMessage.info('正在上传,请稍后');
              uploadFile(file, url => {
                insertFn(url, '', '');
              });
            }
          }
        }
      }
    };
  },
  methods: {
    onCoverImageUploaded(url) {
      this.coverImage = url;
    },
    publish() {
      if (this.postId > 0) {
        api.updateTrainActivity({
          'id': this.postId,
          'html': this.valueHtml,
          'title': this.title,
          'coverImage': this.coverImage
        }).then(() => {
          this.$router.replace('/teach/trends');
        });
      } else {
        api.createTrainActivity({
          'html': this.valueHtml,
          'title': this.title,
          'coverImage': this.coverImage
        }).then(() => {
          this.$router.replace('/teach/trends');
        });
      }
    },
    handleCreated(editor) {
      this.editor = editor;
    }
  },
  beforeUnmount() {
    // if (this.editor) {
    // this.editor.destory();
    // }
  },
  mounted() {
    if (this.postId > 0) {
      api.getTrendActivity(this.postId).then(ret => {
        this.title = ret.title;
        this.valueHtml = ret.html;
        this.coverImage = ret.coverImage;
      });
    }
  }
};