import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    style: {
      "width": "100%"
    },
    data: $props.table.list,
    "header-row-style": {
      color: 'black'
    },
    "header-cell-style": {
      background: '#F0F0F0'
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.table.header, (h, i) => {
      return _openBlock(), _createBlock(_component_el_table_column, {
        key: h.name,
        sortable: !!h.sortable,
        prop: h.name,
        label: h.label,
        "show-overflow-tooltip": i + 1 < $props.table.header.length /** 最后一栏不省略 */,
        width: h.width
      }, _createSlots({
        _: 2
      }, [h.hasSlot ? {
        name: "default",
        fn: _withCtx(scope => [_renderSlot(_ctx.$slots, h.name, {
          rowData: scope.row[h.name],
          rowId: scope.row['id']
        }, undefined, true)]),
        key: "0"
      } : undefined]), 1032, ["sortable", "prop", "label", "show-overflow-tooltip", "width"]);
    }), 128))]),
    _: 3
  }, 8, ["data"])), [[_directive_loading, $props.loading]]);
}