import api from "@/api";
import DialogCustomView from "@/components/DialogCustomView";
import OssUploader from "@/components/OssUploader";
import BatterTableDataView from "@/components/BatterTableDataView";
import { ElMessage } from "element-plus";
import DialogView from "@/components/DialogView";
export default {
  name: "UserListView",
  components: {
    DialogView,
    OssUploader,
    DialogCustomView,
    BatterTableDataView
  },
  methods: {
    deductUserBalance(userId) {
      this.deductBalanceVisible = true;
      this.deductUserId = userId;
    },
    deductBalance(data) {
      data.userId = this.deductUserId;
      api.deductBalance(data).then(() => {
        this.deductBalanceVisible = false;
        this.$refs.userTable.fetchData();
      });
    },
    downloadTplFile() {
      window.open('https://cdn.waityou.online/7880d8cc-26ba-9173-4d94-25694e16b26c.xlsx', '_blank');
    },
    onUploadedFile(url) {
      api.importUserFromFile(url).then(ret => {
        ElMessage.success(ret);
        this.importUserVisible = false;
        this.$refs.userTable.fetchData();
      });
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.accountState == 'NORMAL';
        this.statisticsMap[itm.id] = itm.mobileStatistics;
        this.loginMap[itm.id] = itm.enableManagerLogin;
      }
    },
    enableChange(id, val) {
      this.enableMap[id] = val;
      api.updateUserEnableState(id, val);
    },
    mobileStatisticsChange(id, val) {
      this.statisticsMap[id] = val;
      api.updateUserMobileStatistics(id, val);
    },
    enableManagerLoginChange(id, val) {
      this.loginMap[id] = val;
      api.updateUserManagerLogin(id, val);
    }
  },
  data() {
    return {
      deductUserId: null,
      importUserVisible: false,
      deductBalanceVisible: false,
      enableMap: {},
      statisticsMap: {},
      loginMap: {},
      initQuery: {
        userId: this.$route.query.userId
      }
    };
  }
};