import api from "@/api";
import TableDataView from "@/components/TableDataView";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  name: "BannerList",
  components: {
    TableDataView
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  data() {
    return {
      listFun: api.getBannerList,
      createFun: api.createBanner,
      updateFun: api.updateBanner,
      deleteFun: api.deleteBanner
    };
  }
};