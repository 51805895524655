import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}
const api = {
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret)
    }, getQueryForm(formId, query) {
        return axios.get(path.baseUrl + path.queryForm[formId], {params: query})
    }, get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    }, post(url, data) {
        return axios.post(path.baseUrl + url, data)
    }, getDialogForm(dataId, bindId) {
        if (bindId != null) {
            return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
        }
        return axios.get(path.baseUrl + path.dialogs[dataId])
    }, getMenus() {
        return axios.get(path.baseUrl + path.menus)
    }, getUserInfo(accessToken) {
        console.log('getUserInfo accessToken', accessToken);
        return axios.get(path.baseUrl + path.userInfo)
    }, getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken)
    }, login(data) {
        return axios.post(path.baseUrl + path.login, data)
    }, logout() {
        return axios.get(path.baseUrl + path.logout)
    }, createBanner(data) {
        return axios.post(path.baseUrl + path.createBanner, data);
    },

    updateSystemConfig(data) {
        return axios.post(path.baseUrl + path.updateSystemConfig, data);
    }, getSystemConfig() {
        return axios.get(path.baseUrl + path.getSystemConfig)
    }, updateImageValue(data) {
        return axios.post(path.baseUrl + path.updateImageValue, data);
    }, getNextAuditPost() {
        return axios.get(path.baseUrl + path.getNextAuditPost)
    }, auditPost(data) {
        return axios.post(path.baseUrl + path.auditPost, data);
    }, getNextAuditComment() {
        return axios.get(path.baseUrl + path.getNextAuditComment)
    }, auditComment(data) {
        return axios.post(path.baseUrl + path.auditComment, data);
    }, getNextAuditUser() {
        return axios.get(path.baseUrl + path.getNextAuditUser)
    }, auditUser(data) {
        return axios.post(path.baseUrl + path.auditUser, data);
    }, updateQuestionRepo(data) {
        return axios.post(path.baseUrl + path.updateQuestionRepo, data);
    }, updateQuestionRepoEnableState(repoId, enable) {
        return axios.get(path.baseUrl + stringFormat(path.updateQuestionRepoEnableState, [repoId, enable]))
    }, deleteStudyRepo(repoId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteStudyRepo, [repoId]))
    }, createSpecialSubject(data) {
        return axios.post(path.baseUrl + path.createSpecialSubject, data);
    }, listSpecialSubjects(query) {
        return axios.get(path.baseUrl + path.listSpecialSubjects, {params: query})
    }, queryReportTableData(data) {
        return axios.post(path.baseUrl + path.queryReportTableData, data)
    }, createRole(data) {
        return axios.post(path.baseUrl + path.createRole, data);
    }, updateRepoCategoryEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateRepoCategoryEnableState, [id, val]))
    }, updateRepoCategoryVisibleState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateRepoCategoryVisibleState, [id, val]))
    },
    createRepoCategory(data) {
        return axios.post(path.baseUrl + path.createRepoCategory, data);
    },
    updateTrainTopicEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateTrainTopicEnableState, [id, val]))
    },
    updateTrainTopicVisibleState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateTrainTopicVisibleState, [id, val]))
    },
    createTrainTopic(data) {
        return axios.post(path.baseUrl + path.createTrainTopic, data);
    },
    updateExamTypeEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateExamTypeEnableState, [id, val]))
    },
    updateExamTypeVisibleState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateExamTypeVisibleState, [id, val]))
    },
    createExamType(data) {
        return axios.post(path.baseUrl + path.createExamType, data);
    },
    updateBannerEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateBannerEnableState, [id, val]))
    },
    updateFriendLinkEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateFriendLinkEnableState, [id, val]))
    },
    createFriendLink(data) {
        return axios.post(path.baseUrl + path.createFriendLink, data);
    },
    getRegionList(superId, deep = 1) {
        return axios.get(path.baseUrl + stringFormat(path.getRegionList, [superId, deep]))
    },
    updateRegionOrder(orderIndexes) {
        return axios.post(path.baseUrl + path.updateRegionOrder, orderIndexes);
    },
    updateOrganizationEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateOrganizationEnableState, [id, val]))
    },
    updateUserEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserEnableState, [id, val]))
    },
    updateUserMobileStatistics(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserMobileStatistics, [id, val]))
    },
    updateUserManagerLogin(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserManagerLogin, [id, val]))
    },
    getMapKey() {
        return axios.get(path.baseUrl + path.getMapKey)
    },
    location2address(location) {
        return axios.get(path.baseUrl + stringFormat(path.location2address, location))
    },
    createTeachPlan(data) {
        return axios.post(path.baseUrl + path.createTeachPlan, data);
    },
    editTeachPlan(data) {
        return axios.post(path.baseUrl + path.updateTeachPlan, data);
    },
    getTeachClassInfo(classId) {
        return axios.get(path.baseUrl + stringFormat(path.getTeachClassInfo, [classId]))
    },
    createQuestion(data) {
        return axios.post(path.baseUrl + path.createQuestion, data);
    },
    getQuestionDetail(id) {
        return axios.get(path.baseUrl + stringFormat(path.getQuestionDetail, [id]))
    },
    updateQuestion(data) {
        return axios.post(path.baseUrl + path.updateQuestion, data);
    },
    importQuestionFromFile(url) {
        return axios.get(path.baseUrl + stringFormat(path.importQuestionFromFile, [url]))
    },
    getQuestionList(repoId) {
        return axios.get(path.baseUrl + stringFormat(path.getQuestionList, [repoId]))
    },
    updateClassQuestions(data) {
        return axios.post(path.baseUrl + path.updateClassQuestions, data);
    },
    updateTeachDescription(data) {
        return axios.post(path.baseUrl + path.updateTeachDescription, data);
    },
    updateTeachChapters(data) {
        return axios.post(path.baseUrl + path.updateTeachChapters, data);
    },
    updateTeachVideo(data) {
        return axios.post(path.baseUrl + path.updateTeachVideo, data);
    },
    updateTeachDocument(data) {
        return axios.post(path.baseUrl + path.updateTeachDocument, data);
    },
    createExamByQuestionIds(data) {
        return axios.post(path.baseUrl + path.createExamByQuestionIds, data);

    },
    getRepoCategories() {
        return axios.get(path.baseUrl + path.getRepoCategories)
    },
    getExamTypes() {
        return axios.get(path.baseUrl + path.getExamTypes)
    },
    getQuestionsByIds(data) {
        return axios.post(path.baseUrl + path.getQuestionsByIds, data);
    },
    getQuestionListByExamId(examId) {
        return axios.get(path.baseUrl + stringFormat(path.getQuestionListByExamId, [examId]))
    },
    getExamById(examId) {
        return axios.get(path.baseUrl + stringFormat(path.getExamById, [examId]))
    },
    createClassVideo(data) {
        return axios.post(path.baseUrl + path.createClassVideo, data);
    },
    updateClassVideo(data) {
        return axios.post(path.baseUrl + path.updateClassVideo, data);
    },
    getFileList(query) {
        return axios.get(path.baseUrl + path.getFileList, {params: query})
    },
    deleteFile(id) {
        return axios.get(path.baseUrl + stringFormat(path.deleteFile, [id]))
    },
    createClassDoc(data) {
        return axios.post(path.baseUrl + path.createClassDoc, data);
    },
    updateClassDoc(data) {
        return axios.post(path.baseUrl + path.updateClassDoc, data);
    },
    createTrainActivity(data) {
        return axios.post(path.baseUrl + path.createTrainActivity, data);
    },
    updateTrainActivity(data) {
        return axios.post(path.baseUrl + path.updateTrainActivity, data);
    },
    changeTrendEnable(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.changeTrendEnable, [id, val]))
    },
    getTrendActivity(postId) {
        return axios.get(path.baseUrl + stringFormat(path.getTrainActivity, [postId]))
    },
    onSpecialSubjectVisibleChange(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.changeSubjectVisible, [id, val]))
    },
    updateSpecialSubject(data) {
        return axios.post(path.baseUrl + path.updateSpecialSubject, data);
    },
    updateDataFileVisibleState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateDataFileVisibleState, [id, val]))
    },
    deleteSpecialSubject(id) {
        return axios.get(path.baseUrl + stringFormat(path.deleteSpecialSubject, [id]))
    },
    bindRepoId(data) {
        return axios.post(path.baseUrl + path.bindRepoId, data);
    },
    batchCreateQuestion(question) {
        return axios.post(path.baseUrl + path.batchCreateQuestion, question);
    },
    getDashboardData(query) {
        return axios.post(path.baseUrl + path.getDashboardData, query)
    },
    getClassEvaluate(classId) {
        return axios.get(path.baseUrl + stringFormat(path.getClassEvaluate, [classId]))
    },
    getClassUpdates(classId) {
        return axios.get(path.baseUrl + stringFormat(path.getClassUpdates, [classId]))
    },
    exportSignInList(classId) {
        return axios.get(path.baseUrl + stringFormat(path.exportSignInList, [classId]))
    },
    fixSignIn(classId, userId) {
        return axios.get(path.baseUrl + stringFormat(path.fixSignIn, [classId, userId]))
    },
    updateSpecialSubjectSetting(data) {
        return axios.post(path.baseUrl + path.updateSpecialSubjectSetting, data);
    },
    getSpecialSubjectSetting() {
        return axios.get(path.baseUrl + path.getSpecialSubjectSetting)
    },
    updateCorrection(data) {
        return axios.post(path.baseUrl + path.updateCorrection, data)
    },
    updateTrainActivityAudit(data) {
        return axios.post(path.baseUrl + path.updateTrainActivityAudit, data)
    },
    queryPlace(data) {
        return axios.post(path.baseUrl + path.queryPlace, data)
    },
    queryRepoMap(data) {
        return axios.post(path.baseUrl + path.queryRepoMap, data);
    },
    checkAndParseQuestion(data) {
        return axios.post(path.baseUrl + path.checkAndParseQuestion, data);
    },
    importUserFromFile(url) {
        return axios.get(path.baseUrl + stringFormat(path.importUserFromFile, [url]))
    },
    exportReportTable(data) {
        return axios.post(path.baseUrl + path.exportReportTable, data)
    },
    createTrain() {
        return axios.get(path.baseUrl + path.createTrain)
    },
    updateTrainPlan(data) {
        return axios.post(path.baseUrl + path.updateTrainPlan, data);
    },
    getTrainTopics() {
        return axios.get(path.baseUrl + path.getTrainTopics)
    },
    getRepoDetail(repoId) {
        return axios.get(path.baseUrl + stringFormat(path.getRepoDetail, [repoId]))
    },
    generateQuestionsByTrainId(planId) {
        return axios.get(path.baseUrl + stringFormat(path.generateQuestionsByTrainId, [planId]))
    },
    resetTrainQuestions(planId) {
        return axios.get(path.baseUrl + stringFormat(path.resetTrainQuestions, [planId]))
    },
    removeQuestion(data) {
        return axios.post(path.baseUrl + path.removeQuestion, data);
    },
    submitTrain(planId) {
        return axios.get(path.baseUrl + stringFormat(path.submitTrain, [planId]))
    },
    updateManualTrainQuestion(data) {
        return axios.post(path.baseUrl + path.updateManualTrainQuestion, data);
    },
    createEditingExam(query) {
        return axios.get(path.baseUrl + path.createEditingExam, {params: query})
    },
    updateExam(data) {
        return axios.post(path.baseUrl + path.updateExam, data);
    },
    resetExamQuestions(examId) {
        return axios.get(path.baseUrl + stringFormat(path.resetExamQuestions, [examId]))
    },
    generateQuestionsByExamId(examId) {
        return axios.get(path.baseUrl + stringFormat(path.generateQuestionsByExamId, [examId]))
    },
    removeExamQuestion(query) {
        return axios.post(path.baseUrl + path.removeExamQuestion, query);
    },
    submitExam(query) {
        return axios.get(path.baseUrl + path.submitExam, {params: query});
    },
    updateExamQuestions(data) {
        return axios.post(path.baseUrl + path.updateExamQuestions, data);
    },
    updateManualExamQuestion(data) {
        return axios.post(path.baseUrl + path.updateManualExamQuestion, data);
    },
    getTableStructure() {
        return axios.get(path.baseUrl + path.getTableStructure);
    },
    updateSignPaper(data) {
        return axios.post(path.baseUrl + path.updateSignPaper, data);
    },
    deleteSignInPaper(classId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteSignInPaper, [classId]))
    },
    deductBalance(data) {
        return axios.post(path.baseUrl + path.deductBalance, data);
    },
    getUserAnswerRecords(query) {
        return axios.get(path.baseUrl + path.getUserAnswerRecords, {params: query});
    },
    getUserInfoById(userId) {
        return axios.get(path.baseUrl + stringFormat(path.getUserInfoById, [userId]))
    },
    changeEnableDownloadRecord(query) {
        return axios.get(path.baseUrl + path.changeEnableDownloadRecord, {params: query});
    }
}

export default api;