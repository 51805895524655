import api from "@/api"
import OSS from "ali-oss";
import {ElMessage} from "element-plus";

let ossClient = null
let ossDomain = null
let ossFolder = null
api.getOssKeySecret().then(cfg => {
    ossClient = new OSS({
        region: cfg.region,
        accessKeyId: cfg.accessKeyId,
        accessKeySecret: cfg.accessKeySecret,
        stsToken: cfg.securityToken,
        bucket: cfg.bucket,
        endpoint: cfg.endpoint,
        secure: true
    });
    ossDomain = cfg.domain;
    ossFolder = cfg.folder;
})

function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function uploadFile(file, callback) {
    let fileType = file.name.substr(file.name.lastIndexOf('.'))
    let fileName = (ossFolder ? ossFolder + '/' : 'user-upload/') + guid() + fileType;
    // 上传文件,这里是上传到OSS的 uploads文件夹下
    ossClient.put(fileName, file).then(res => {
        if (res.res.statusCode === 200) {
            ElMessage.success('上传成功')
            console.log('上传成功', ossDomain + '/' + fileName)
            callback(ossDomain + '/' + fileName)
        } else {
            ElMessage.error('上传失败')
        }
    })
}