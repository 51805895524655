import DashboardNumberTable from "@/components/dashboard/DashboardNumberTable";
export default {
  name: "DistrictMiniTable",
  components: {
    DashboardNumberTable
  },
  props: {
    reportTable: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      activeTab: "first"
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};