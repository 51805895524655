import DialogView from "@/components/DialogView";
import api from "@/api";
export default {
  name: "IndexSpecialSubjectConfigView",
  components: {
    DialogView
  },
  methods: {
    config(type) {
      this.type = type;
      this.editVisible = true;
    },
    submitEdit(data) {
      data.type = this.type;
      api.updateSpecialSubjectSetting(data).then(() => {
        this.editVisible = false;
        this.init();
      });
    },
    init() {
      api.getSpecialSubjectSetting().then(ret => {
        this.setting = ret;
      });
    }
  },
  data() {
    return {
      type: 0,
      setting: {},
      editVisible: false
    };
  },
  mounted() {
    this.init();
  }
};