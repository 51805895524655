import "core-js/modules/es.array.push.js";
export default {
  name: "DashboardNumberTable",
  props: {
    mapping: {
      type: Object,
      default: () => {
        return {
          unitNumberKey: '',
          actualNumberKey: '',
          unitPeopleNumberKey: '',
          actualPeopleNumberKey: ''
        };
      }
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      tableData: [],
      arraySpanMethod: []
    };
  },
  methods: {
    init() {
      let unitNumberCnt = 0;
      let actualNumberCnt = 0;
      let unitPeopleNumber = 0;
      let actualPeopleNumber = 0;
      for (let r of this.data) {
        if (r[this.mapping.unitNumberKey]) {
          unitNumberCnt += r[this.mapping.unitNumberKey];
        }
        if (r[this.mapping.actualNumberKey]) {
          actualNumberCnt += r[this.mapping.actualNumberKey];
        }
        if (r[this.mapping.unitPeopleNumberKey]) {
          unitPeopleNumber += r[this.mapping.unitPeopleNumberKey];
        }
        if (r[this.mapping.actualPeopleNumberKey]) {
          actualPeopleNumber += r[this.mapping.actualPeopleNumberKey];
        }
      }
      this.tableData.push({
        'unitNumber': unitNumberCnt,
        'actualNumber': actualNumberCnt,
        'ratio': unitNumberCnt <= 0 ? '0%' : (actualNumberCnt * 100 / unitNumberCnt).toFixed(2) + '%',
        'unitPeopleNumber': unitPeopleNumber,
        'actualPeopleNumber': actualPeopleNumber,
        'peopleRatio': unitPeopleNumber <= 0 ? '0%' : (actualPeopleNumber * 100.0 / unitPeopleNumber).toFixed(2) + '%'
      });
    }
  },
  mounted() {
    this.init();
  }
};