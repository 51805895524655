import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "StudyDataView",
  components: {
    BatterTableDataView
  },
  data() {
    return {
      switches: {},
      initQuery: {},
      editDialogVisible: false,
      editBindId: 0,
      write: false
    };
  },
  methods: {
    onFetchData(data) {
      this.write = data.write;
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['enable'];
      }
    },
    updateData(data) {
      data['repoId'] = this.editBindId;
      api.updateQuestionRepo(data).then(() => {
        this.editDialogVisible = false;
        this.initQuery = {
          'refresh': new Date()
        };
      });
    },
    updateEnableState(repoId, enable) {
      api.updateQuestionRepoEnableState(repoId, enable).then(() => {
        this.initQuery = {
          'refresh': new Date()
        };
      });
    },
    deleteRepo(repoId) {
      api.deleteStudyRepo(repoId).then(() => {
        this.initQuery = {
          'refresh': new Date()
        };
      });
    }
  }
};