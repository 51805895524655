import DataTable from "@/components/DataTable";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import DynaQueryForm from "@/components/DynaQueryForm";
import DialogView from "@/components/DialogView";
export default {
  name: "TableDataView",
  components: [DataTable, DynaQueryForm, DialogView],
  setup() {
    return {
      locale: zhCn
    };
  },
  props: {
    updateIdKey: {
      type: String,
      default: null
    },
    detailIdKey: {
      type: String,
      default: null
    },
    formId: {
      type: Number,
      default: 0
    },
    queryForm: {
      type: Boolean,
      default: false
    },
    listFunction: {
      type: Function,
      default: null
    },
    createFunction: {
      type: Function,
      default: null
    },
    deleteFunction: {
      type: Function,
      default: null
    },
    updateFunction: {
      type: Function,
      default: null
    },
    createDialogId: {
      type: Number,
      default: null
    },
    editDialogId: {
      type: Number,
      default: null
    },
    detailDialogId: {
      type: Number,
      default: null
    },
    initQuery: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    initQuery() {
      this.fetchData();
      this.$emit('onInitQuery', this.initQuery);
    }
  },
  methods: {
    createData(data) {
      this.createFunction(data).then(() => {
        this.createDialogVisible = false;
        this.createDialogDataId = 0;
        this.fetchData();
      });
    },
    updateData(data) {
      data[this.updateIdKey] = this.editBindId;
      this.updateFunction(data).then(() => {
        this.editDialogVisible = false;
        this.editBindId = 0;
        this.fetchData();
      });
    },
    addMore() {
      if (this.createFunction && this.createDialogId) {
        this.createDialogVisible = true;
      } else {
        this.$emit('addMore');
      }
    },
    onEditData(data) {
      if (this.updateIdKey) {
        this.editDialogVisible = true;
        this.editBindId = data['id'];
      } else {
        this.$emit('onEditData', data);
      }
    },
    onDetailData(data) {
      if (this.detailIdKey) {
        this.detailDialogVisible = true;
        this.detailBindId = data['id'];
      } else {
        this.$emit('onDetailData', data);
      }
    },
    onDeleteData(data) {
      this.deleteFunction(data['id']).then(() => {
        this.fetchData();
      });
    },
    gotoPage(e) {
      this.page = e;
      this.fetchData();
    },
    onSizeChange(e) {
      this.page = 1;
      this.pageSize = e;
      this.fetchData();
    },
    onQuery(data) {
      this.query = data;
      this.fetchData();
      this.$emit('onQuery', data);
    },
    onResetQuery() {
      this.query = {};
      this.fetchData();
      this.$emit('onResetQuery', {});
    },
    fetchData() {
      this.tableDataLoading = true;
      for (let k of Object.keys(this.initQuery)) {
        if (!this.query[k]) {
          this.query[k] = this.initQuery[k];
        }
      }
      this.query['page'] = this.page - 1;
      this.query['pageSize'] = this.pageSize;
      this.listFunction(this.query).then(ret => {
        this.tableDataLoading = false;
        this.tableData = ret;
        this.total = ret.total;
        this.page = ret.page + 1;
        this.pageSize = ret.pageSize;
      }).catch(() => {
        this.tableDataLoading = false;
      });
    }
  },
  data() {
    return {
      createDialogVisible: false,
      editDialogVisible: false,
      detailDialogVisible: false,
      editBindId: 0,
      detailBindId: 0,
      query: {},
      form: {},
      total: 0,
      page: 1,
      pageSize: 10,
      tableDataLoading: false,
      tableData: {
        title: "...",
        header: [],
        list: [],
        textStyleMapping: {},
        textMapping: {}
      }
    };
  },
  mounted() {
    this.fetchData();
    this.$emit('onInitQuery', this.initQuery);
  }
};