import SvgIcon from "@/components/SvgIcon";
import api from "@/api";
import DialogView from "@/components/DialogView";
export default {
  name: "SpecialSubjectItem",
  components: {
    SvgIcon,
    DialogView
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    updateSpecialSubject(data) {
      data['id'] = this.data.id;
      api.updateSpecialSubject(data).then(() => {
        this.$emit('itemUpdated', data);
        this.editVisible = false;
      });
    },
    onVisibleChange(val) {
      api.onSpecialSubjectVisibleChange(this.data.id, val).then(() => {
        this.visible = val;
      });
    }
  },
  watch: {
    data(newValue) {
      this.visible = newValue.visible;
    }
  },
  data() {
    return {
      visible: false,
      editVisible: false
    };
  },
  mounted() {
    this.visible = this.data.visible;
  }
};