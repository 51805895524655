import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ab47d58"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "itemContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_svg_icon, {
    "icon-class": "add",
    size: "100px"
  })]);
}