import "core-js/modules/es.array.push.js";
import api from "@/api";
import { ElLoading } from 'element-plus';
import FileImportPage from "@/components/question/FileImportPage";
import FileImportReview from "@/components/question/FileImportReview";
import QuestionCreateAndReview from "@/components/question/QuestionCreateAndReview";
export default {
  name: "QuestionImportPageView",
  components: {
    FileImportPage,
    FileImportReview,
    QuestionCreateAndReview
  },
  data() {
    return {
      step: 0,
      importType: '批量导入',
      fileImported: []
    };
  },
  methods: {
    //单个导入绑定
    submitQList(qids) {
      this.$emit('submit', qids);
    },
    //文件导入
    onFileUploaded(url) {
      let loading = ElLoading.service({
        lock: true,
        text: '导入中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.importQuestionFromFile(url).then(ret => {
        this.fileImported = ret;
        this.step = 1;
        console.log('解析结果', ret);
      }).finally(() => {
        loading.close();
      });
    },
    saveUploaded(parsed) {
      api.batchCreateQuestion(parsed).then(ret => {
        let qids = [];
        for (let r of ret) {
          qids.push(r.id);
        }
        this.$emit('submit', qids);
      });
    }
  }
};