import BatterTableDataView from "@/components/BatterTableDataView";
import DialogView from "@/components/DialogView";
import api from "@/api";
export default {
  name: "TeachListView",
  components: {
    DialogView,
    BatterTableDataView
  },
  data() {
    return {
      createVisible: false,
      initQuery: {}
    };
  },
  methods: {
    addMore() {
      this.createVisible = true;
    },
    createRole(data) {
      api.createRole(data).then(() => {
        this.createVisible = false;
        this.initQuery = {
          refresh: new Date()
        };
      });
    }
  }
};