const base = {
    baseUrl: "",
    menus: "/api/manager/menu/menuList",
    userInfo: "/api/manager/info/my",
    loginCaptchaToken: "/login/captchaToken",
    login: "/login/accountLogin",
    logout: "/api/manager/info/logout",

    createBanner: "/api/manager/setting/mobile/createBanner",

    getOssKeySecret: "/api/manager/oss/getOssKeySecret",
    updateSystemConfig: '/api/manager/config/update',
    getSystemConfig: '/api/manager/config/list',
    updateImageValue: '/api/manager/config/updateImageValue',
    getNextAuditPost: '/api/manager/audit/getNextAuditPost',
    auditPost: '/api/manager/audit/auditPost',
    auditComment: '/api/manager/audit/auditComment',
    auditUser: '/api/manager/audit/auditUser',
    getNextAuditComment: '/api/manager/audit/getNextAuditComment',
    getNextAuditUser: '/api/manager/audit/getNextAuditUser',
    updateQuestionRepo: '/api/manager/study/repo/update',
    updateQuestionRepoEnableState: '/api/manager/study/repo/updateEnable?repoId={0}&enable={1}',
    deleteStudyRepo: '/api/manager/study/repo/delete?repoId={0}',
    createSpecialSubject: '/api/manager/special/subject/create',
    listSpecialSubjects: '/api/manager/special/subject/list',
    changeSubjectVisible: '/api/manager/special/subject/changeVisible?id={0}&val={1}',
    queryReportTableData: '/api/manager/report/table',
    createRole: '/api/manager/role/create',
    updateRepoCategoryEnableState: '/api/manager/category/updateRepoCategoryEnableState?id={0}&value={1}',
    updateRepoCategoryVisibleState: '/api/manager/category/updateRepoCategoryVisibleState?id={0}&value={1}',
    createRepoCategory: '/api/manager/category/create',
    updateTrainTopicEnableState: '/api/manager/topic/updateEnableState?id={0}&value={1}',
    updateTrainTopicVisibleState: '/api/manager/topic/updateVisibleState?id={0}&value={1}',
    createTrainTopic: '/api/manager/topic/create',
    updateExamTypeEnableState: '/api/manager/exam/type/updateEnableState?id={0}&value={1}',
    updateExamTypeVisibleState: '/api/manager/exam/type/updateVisibleState?id={0}&value={1}',
    createExamType: '/api/manager/exam/type/create',
    updateBannerEnableState: '/api/manager/setting/mobile/updateBannerEnableState?id={0}&value={1}',
    updateFriendLinkEnableState: '/api/manager/setting/mobile/updateFlEnableState?id={0}&value={1}',
    createFriendLink: '/api/manager/setting/mobile/createFl',
    getRegionList: '/api/manager/region/list?superId={0}&deep={1}',
    updateRegionOrder: '/api/manager/region/updateRegionOrder',
    updateOrganizationEnableState: '/api/manager/organization/updateEnableState?id={0}&value={1}',
    updateUserEnableState: '/api/manager/user/updateEnableState?id={0}&value={1}',
    updateUserMobileStatistics: '/api/manager/user/updateUserMobileStatistics?id={0}&value={1}',
    updateUserManagerLogin: '/api/manager/user/updateUserManagerLogin?id={0}&value={1}',
    getMapKey: '/api/manager/setting/mobile/getMapKey',
    location2address: '/api/manager/amap/location2address?location={0},{1}',
    createTeachPlan: '/api/manager/teach/create',
    updateTeachPlan: '/api/manager/teach/update',
    getTeachClassInfo: '/api/manager/teach/info?id={0}',
    createQuestion: '/api/manager/question/create',
    updateQuestion: '/api/manager/question/update',
    getQuestionDetail: '/api/manager/question/detail?id={0}',
    importQuestionFromFile: '/api/manager/question/importQuestionFromFile?url={0}',
    getQuestionList: '/api/manager/question/listWithSize?repoId={0}&pageSize=500',
    updateClassQuestions: '/api/manager/teach/updateClassQuestions',
    updateTeachDescription: '/api/manager/teach/updateDescription',
    updateTeachChapters: '/api/manager/teach/updateChapters',
    updateTeachVideo: '/api/manager/teach/updateVideo',
    updateTeachDocument: '/api/manager/teach/updateDocument',
    createExamByQuestionIds: '/api/manager/exam/createExamByQuestionIds',
    getRepoCategories: '/api/manager/category/list4select',
    getExamTypes: '/api/manager/exam/type/list4select',
    getQuestionsByIds: '/api/manager/question/getQuestionsByIds',
    getQuestionListByExamId: '/api/manager/question/getQuestionListByExamId?examId={0}',
    getExamById: '/api/manager/exam/info?examId={0}',
    createClassVideo: '/api/manager/file/createTrainDocVideo',
    createClassDoc: '/api/manager/file/createTrainDoc',
    updateClassVideo: '/api/manager/file/updateTrainDocVideo',
    updateClassDoc: '/api/manager/file/updateTrainDoc',
    getFileList: '/api/manager/file/list',
    deleteFile: '/api/manager/file/delete?id={0}',
    createTrainActivity: '/api/manager/trends/create',
    updateTrainActivity: '/api/manager/trends/update',
    changeTrendEnable: '/api/manager/trends/switchEnable?id={0}&val={1}',
    getTrainActivity: '/api/manager/trends/info?id={0}',
    updateSpecialSubject: '/api/manager/special/subject/update',
    updateDataFileVisibleState: '/api/manager/file/changeFileVisible?id={0}&val={1}',
    deleteSpecialSubject: '/api/manager/special/subject/delete?id={0}',
    bindRepoId: '/api/manager/question/bindRepoId',
    batchCreateQuestion: '/api/manager/question/batchCreate',
    getDashboardData: '/api/manager/dashboard/data',
    getClassEvaluate: '/api/manager/teach/evaluate/list?classId={0}',
    getClassUpdates: '/api/manager/teach/getUpdates?classId={0}',
    exportSignInList: '/api/manager/sign/export?classId={0}',
    fixSignIn: '/api/manager/sign/fixSignIn?classId={0}&userId={1}',
    updateSpecialSubjectSetting: '/api/manager/setting/mobile/updateSpecialSubjectSetting',
    getSpecialSubjectSetting: '/api/manager/setting/mobile/getSpecialSubjectSetting',
    updateCorrection: '/api/manager/correction/update',
    updateTrainActivityAudit: '/api/manager/trends/updateAudit',
    queryPlace: '/api/manager/amap/search',
    queryRepoMap: '/api/manager/selector/queryRepoMap',
    checkAndParseQuestion: '/api/manager/question/checkAndParseQuestion',
    importUserFromFile: '/api/manager/user/importUserFromFile?url={0}',
    exportReportTable: '/api/manager/report/export',
    createTrain: '/api/manager/teach/createTrain',
    updateTrainPlan: '/api/manager/teach/updateTrainPlan',
    getTrainTopics: '/api/manager/topic/list4select',
    getRepoDetail: '/api/manager/study/repo/detail?repoId={0}',
    generateQuestionsByTrainId: '/api/manager/teach/generateQuestion?trainId={0}',
    generateQuestionsByExamId: '/api/manager/exam/generateQuestion?examId={0}',
    resetTrainQuestions: '/api/manager/teach/resetTrainQuestions?trainId={0}',
    resetExamQuestions: '/api/manager/exam/resetExamQuestions?examId={0}',
    removeQuestion: '/api/manager/teach/removeQuestion',
    removeExamQuestion: '/api/manager/exam/removeQuestion',
    submitTrain: '/api/manager/teach/submitTrain?trainId={0}',
    updateManualTrainQuestion: '/api/manager/teach/updateManualTrainQuestion',
    createEditingExam: '/api/manager/exam/createEditingExam',
    updateExam: '/api/manager/exam/updateExam',
    submitExam: '/api/manager/exam/submitExam',
    updateExamQuestions: '/api/manager/exam/updateExamQuestions',
    updateManualExamQuestion: '/api/manager/exam/updateManualExamQuestion',
    getTableStructure: '/api/manager/report/getTableStructure',
    updateSignPaper: '/api/manager/teach/updateSignPaper',
    deleteSignInPaper: '/api/manager/teach/deleteSignInPaperFile?planId={0}',
    deductBalance: '/api/manager/user/deductBalance',
    getUserAnswerRecords: '/api/manager/exam/record/detail',
    getUserInfoById: '/api/manager/user/getUserInfoById?id={0}',
    changeEnableDownloadRecord: '/api/manager/exam/changeEnableDownloadRecord',
    queryForm: {
        1: '/api/manager/teach/getQueryForm',
        2: '/api/manager/trends/getQueryForm',
        3: '/api/manager/study/repo/getQueryForm',
        4: '/api/manager/question/getQueryForm',
        5: '/api/manager/exam/getQueryForm',
        6: '/api/manager/organization/getQueryForm',
        7: '/api/manager/user/getQueryForm',
        8: '/api/manager/special/subject/getQueryForm',
        9: '/api/manager/correction/getQueryForm',
        10: '/api/manager/dashboard/getQueryForm',
        11: '/api/manager/exam/record/getQueryForm',
        12: '/api/manager/exam/wrong/book/getQueryForm',
    },
    dialogs: {
        1: '/api/manager/teach/getCreateForm',
        2: '/api/manager/study/repo/getCreateForm',
        3: '/api/manager/study/repo/getEditForm?repoId={0}',
        4: '/api/manager/question/getCreateForm',
        5: '/api/manager/question/getEditForm?questionId={0}',
        6: '/api/manager/special/subject/getCreateForm',
        7: '/api/manager/role/getCreateForm',
        8: '/api/manager/role/getEditForm?roleId={0}',
        9: '/api/manager/category/getCreateForm',
        10: '/api/manager/category/getUpdateForm?id={0}',
        11: '/api/manager/topic/getUpdateForm?id={0}',
        12: '/api/manager/topic/getCreateForm',
        13: '/api/manager/exam/type/getUpdateForm?id={0}',
        14: '/api/manager/exam/type/getCreateForm',
        15: '/api/manager/setting/mobile/getBannerCreateForm',
        16: '/api/manager/setting/mobile/getBannerEditForm?id={0}',
        17: '/api/manager/setting/mobile/getFlEditForm?id={0}',
        18: '/api/manager/setting/mobile/getFlCreateForm',
        19: '/api/manager/organization/getUpdateForm?id={0}',
        20: '/api/manager/organization/getCreateForm',
        21: '/api/manager/user/getCreateForm',
        22: '/api/manager/user/getEditForm?userId={0}',
        23: '/api/manager/user/detail?userId={0}',
        24: '/api/manager/teach/getEditForm?id={0}',
        25: '/api/manager/teach/getDetail?id={0}',
        26: '/api/manager/file/getTrainDocVideoCreateForm',
        27: '/api/manager/file/getTrainDocVideoEditForm?id={0}',
        28: '/api/manager/file/getTrainDocCreateForm',
        29: '/api/manager/file/getTrainDocEditForm?id={0}',
        30: '/api/manager/special/subject/getEditForm?subjectId={0}',
        31: '/api/manager/special/subject/getCreateFileForm',
        32: '/api/manager/special/subject/getEditFileForm?id={0}',
        33: '/api/manager/correction/getEditForm?id={0}',
        34: '/api/manager/correction/detail?id={0}',
        35: '/api/manager/setting/mobile/getSpecialSubjectSettingEditDialog?idx={0}',
        36: '/api/manager/trends/getAuditDialog',
        37: '/api/manager/teach/getPlaceSearchForm?planId={0}',
        38: '/api/manager/teach/getAvailableStudyRepo?planId={0}',
        39: '/api/manager/exam/getAvailableStudyRepo?examId={0}',
        40: '/api/manager/sign/getSignInDetail?signId={0}',
        41: '/api/manager/user/getBalanceDeductForm?userId={0}',
    },
    table: {
        1: {
            queryForm: 1,
            list: '/api/manager/teach/list',
            export: '/api/manager/teach/export',
            listMethod: 'post',
            deleteKey: 'id',
            delete: '/api/manager/teach/delete',
        },
        2: {
            queryForm: 2,
            list: '/api/manager/trends/list',
            deleteKey: 'id',
            delete: '/api/manager/trends/delete',
        },
        3: {
            list: '/api/manager/study/repo/list',
            queryForm: 3,
            create: '/api/manager/study/repo/create',
            createDialog: 2,
            editKey: 'repoId',
            editDialog: 3,
        },
        4: {
            list: '/api/manager/question/list',
            queryForm: 4,
            create: '/api/manager/question/create',
            delete: '/api/manager/question/delete',
            deleteKey: 'id'
        },
        5: {
            list: '/api/manager/correction/list',
            queryForm: 9,
            updateDialog: 33,
            updateKey: 'id',
            update: '/api/manager/correction/update',
            detailDialog: 34,
            detailKey: 'id'
        },
        6: {
            queryForm: 5,
            export: '/api/manager/exam/export',
            list: '/api/manager/exam/list',
            delete: '/api/manager/exam/delete',
            deleteKey: 'id'
        },
        7: {
            list: '/api/manager/selector/repo/list',
        },
        8: {
            list: '/api/manager/selector/question/list',
        },
        9: {
            list: '/api/manager/role/list',
            updateDialog: 8,
            updateKey: 'roleId',
            update: '/api/manager/role/update',
            delete: '/api/manager/role/delete',
            deleteKey: 'roleId',
        },
        10: {
            list: '/api/manager/category/list',
            updateDialog: 10,
            update: '/api/manager/category/update',
            updateKey: 'id',
            delete: '/api/manager/category/delete',
            deleteKey: 'id'
        },
        11: {
            list: '/api/manager/topic/list',
            updateDialog: 11,
            update: '/api/manager/topic/update',
            updateKey: 'id',
            delete: '/api/manager/topic/delete',
            deleteKey: 'id'
        },
        12: {
            list: '/api/manager/exam/type/list',
            updateDialog: 13,
            update: '/api/manager/exam/type/update',
            updateKey: 'id',
            delete: '/api/manager/exam/type/delete',
            deleteKey: 'id'
        },
        13: {
            list: '/api/manager/login/record/list'
        },
        14: {
            list: '/api/manager/setting/mobile/bannerList',
            updateDialog: 16,
            update: '/api/manager/setting/mobile/updateBanner',
            updateKey: 'id',
            delete: '/api/manager/setting/mobile/deleteBanner',
            deleteKey: 'id'
        },
        15: {
            list: '/api/manager/setting/mobile/flList',
            updateDialog: 17,
            update: '/api/manager/setting/mobile/updateFl',
            updateKey: 'id',
            delete: '/api/manager/setting/mobile/deleteFl',
            deleteKey: 'id'
        },
        16: {
            list: '/api/manager/region/regionList',
        },
        17: {
            queryForm: 6,
            list: '/api/manager/organization/list',
            createDialog: 20,
            create: '/api/manager/organization/create',
            updateDialog: 19,
            update: '/api/manager/organization/update',
            updateKey: 'id',
            delete: '/api/manager/organization/delete',
            deleteKey: 'id'
        },
        18: {
            queryForm: 7,
            list: '/api/manager/user/list',
            createDialog: 21,
            create: '/api/manager/user/create',
            updateDialog: 22,
            update: '/api/manager/user/update',
            updateKey: 'id',
            delete: '/api/manager/user/delete',
            deleteKey: 'id',
            detailDialog: 23,
            detailKey: 'userId'
        },
        19: {
            queryForm: 8,
            list: '/api/manager/special/subject/fileList',
            createDialog: 31,
            create: '/api/manager/special/subject/createFile',
            updateDialog: 32,
            update: '/api/manager/special/subject/updateFile',
            updateKey: 'id',
            delete: '/api/manager/special/subject/deleteFile',
            deleteKey: 'id',
        },
        20: {
            queryForm: 7,
            list: '/api/manager/sign/fixSignInList',
        },
        21: {
            queryForm: 11,
            list: '/api/manager/exam/record/list',
            export: '/api/manager/exam/record/export',
        },
        22: {
            list: '/api/manager/exam/record/detail',
        },
        23: {
            list: '/api/manager/message/list',
            delete: '/api/manager/message/delete',
            deleteKey: 'id'
        },
        24: {
            list: '/api/manager/question/getQuestionTableByTrainId'
        },
        25: {
            list: '/api/manager/selector/questionTableByIds',
            listMethod: 'post'
        },
        26: {
            list: '/api/manager/sign/signInList',
            detailDialog: 40,
            detailKey: 'signId',
        },
        27: {
            list: '/api/manager/question/getQuestionTableByExamId'
        },
        28: {
            queryForm: 5,
            list: '/api/manager/exam/examine/list',
            delete: '/api/manager/exam/examine/delete',
            deleteKey: 'id',
            export: '/api/manager/exam/examine/export',
        },
        29: {
            queryForm: 12,
            list: '/api/manager/exam/wrong/book/list',
            listMethod: 'post',
            export: '/api/manager/exam/wrong/book/export',
        },
        30: {
            list: '/api/manager/exam/answer/record/list',
        },
    }

}

export default base;