import {createStore} from 'vuex'

export default createStore({
    state: {
        user: {},
        locationPicker: [120.19732,30.246026]
    },
    getters: {},
    mutations: {
        updateUser(state, val) {
            state.user = val;
        },
        setLocationPicker(state, loc) {
            state.locationPicker = loc;
        },
        resetLocationPicker(state) {
            state.locationPicker = [120.19732,30.246026]
        },
    },
    actions: {},
    modules: {}
})
