import { ElLoading } from "element-plus";
import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "TrainSignedUserCard",
  components: {
    BatterTableDataView
  },
  props: {
    classId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    exportExcel() {
      let loading = ElLoading.service({
        lock: true,
        text: '导出中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.exportSignInList(this.classId).then(url => {
        window.open(url, "_blank");
      }).finally(() => {
        loading.close();
      });
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};