import QuestionSelectPanel from "@/components/question/QuestionSelectPanel";
import api from "@/api";
export default {
  name: "ExamQuestionSelector",
  components: {
    QuestionSelectPanel
  },
  data() {
    return {
      examId: this.$route.params.examId,
      selectedData: {
        selectedIds: [],
        judgeCnt: 0,
        selectCnt: 0,
        mSelectCnt: 0
      }
    };
  },
  methods: {
    saveSelectedIds(qids) {
      api.updateExamQuestions({
        'examId': this.examId,
        'questionIds': qids,
        'saveAndBack': false
      }).then(() => {
        this.init();
      });
    },
    resetSelect() {
      api.resetExamQuestions(this.examId).then(() => {
        this.init();
      });
    },
    saveAndBack(qids) {
      api.updateExamQuestions({
        'examId': this.examId,
        'questionIds': qids,
        'saveAndBack': true
      }).then(() => {
        this.$router.back();
      });
    },
    saveManualIds(qids) {
      api.updateManualExamQuestion({
        examId: this.examId,
        questionIds: qids
      }).then(() => {
        this.$router.back();
      });
    },
    init() {
      api.getExamById(this.examId).then(ret => {
        this.selectedData = {
          selectedIds: ret.questionIds,
          judgeCnt: ret.repoImportedConfigList.map(c => c.singleSize).reduce((a, b) => a + b, 0),
          selectCnt: ret.repoImportedConfigList.map(c => c.multipleSize).reduce((a, b) => a + b, 0),
          mSelectCnt: ret.repoImportedConfigList.map(c => c.judgeSize).reduce((a, b) => a + b, 0)
        };
        console.log('selectedData', this.selectedData);
      });
    }
  },
  mounted() {
    this.init();
  }
};