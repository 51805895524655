import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_radio_button = _resolveComponent("el-radio-button");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_row = _resolveComponent("el-row");
  const _component_FileImportPage = _resolveComponent("FileImportPage");
  const _component_FileImportReview = _resolveComponent("FileImportReview");
  const _component_QuestionCreateAndReview = _resolveComponent("QuestionCreateAndReview");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_radio_group, {
      modelValue: $data.importType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.importType = $event),
      size: "normal"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_radio_button, {
        label: "批量导入"
      }), _createVNode(_component_el_radio_button, {
        label: "单题录入"
      })]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  }), $data.importType == '批量导入' && $data.step === 0 ? (_openBlock(), _createBlock(_component_FileImportPage, {
    key: 0,
    onOnFileUploaded: $options.onFileUploaded
  }, null, 8, ["onOnFileUploaded"])) : _createCommentVNode("", true), $data.importType == '批量导入' && $data.step === 1 ? (_openBlock(), _createBlock(_component_FileImportReview, {
    key: 1,
    onReUpload: _cache[1] || (_cache[1] = $event => $data.step = 0),
    onSave: $options.saveUploaded,
    imported: $data.fileImported
  }, null, 8, ["onSave", "imported"])) : _createCommentVNode("", true), $data.importType == '单题录入' ? (_openBlock(), _createBlock(_component_QuestionCreateAndReview, {
    key: 2,
    onSubmit: $options.submitQList
  }, null, 8, ["onSubmit"])) : _createCommentVNode("", true)], 64);
}