import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createBlock(_component_el_table, {
    data: $data.tableData,
    "span-method": $data.arraySpanMethod,
    border: true,
    "header-cell-style": {
      background: '#E8F3FF',
      color: '#000000',
      textAlign: 'center'
    },
    class: "dntStyle"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: "单位培训覆盖情况"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        prop: "unitNumber",
        label: "单位个数"
      }), _createVNode(_component_el_table_column, {
        prop: "actualNumber",
        label: "实际参训个数"
      }), _createVNode(_component_el_table_column, {
        prop: "ratio",
        label: "当月参训率"
      })]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      prop: "name",
      label: "人员培训覆盖情况"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        prop: "unitPeopleNumber",
        label: "应培训人数"
      }), _createVNode(_component_el_table_column, {
        prop: "actualPeopleNumber",
        label: "实际参训人数"
      }), _createVNode(_component_el_table_column, {
        prop: "peopleRatio",
        label: "当月参训率"
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["data", "span-method"]);
}