export default {
  name: "QuestionRepoSelector",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    avgScoreMode: {
      type: Boolean,
      default: false
    },
    avgScore: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {
        return {
          repoId: 0,
          repoName: 'xxx',
          singleSize: '',
          eachSingleScore: 5,
          multipleSize: '',
          eachMultipleScore: 5,
          judgeSize: '',
          eachJudgeScore: 5
        };
      }
    }
  },
  data() {
    return {
      repo: {}
    };
  },
  methods: {
    onValueChange() {
      this.$emit('repoChange', this.repo);
    },
    init() {
      this.repo = Object.assign({}, this.data);
    }
  },
  mounted() {
    this.init();
  }
};