import SelectorPageView from "@/components/question/SelectorPageView";
import QuestionImportPageView from "@/components/question/QuestionImportPageView";
export default {
  name: "QuestionSelectPanel",
  components: {
    QuestionImportPageView,
    SelectorPageView
  },
  props: {
    questionData: {
      type: Object,
      default: () => {
        return {
          selectedIds: [],
          judgeCnt: 0,
          selectCnt: 0,
          mSelectCnt: 0
        };
      }
    }
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    saveAndBack(ids) {
      this.$emit('saveAndBack', ids);
    },
    saveSelectedIds(ids) {
      this.$emit('saveSelectedIds', ids);
    },
    saveManualIds(ids) {
      this.$emit('saveManualIds', ids);
    }
  }
};