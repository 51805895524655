import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-96d21b20"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "IndexSpecialSubjectConfigViewContainer"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  style: {
    "display": "flex",
    "flex-direction": "column",
    "margin-left": "5px"
  }
};
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [!$data.setting?.leftImage ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = $event => $options.config(0)),
    style: {
      "width": "168px",
      "height": "225px",
      "border-radius": "8px",
      "object-fit": "cover"
    },
    src: "https://cdn.waityou.online/c26ab7f9-8bb9-b020-b02a-637f490f6114.jpg"
  })) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    onClick: _cache[1] || (_cache[1] = $event => $options.config(0)),
    style: {
      "width": "168px",
      "height": "225px",
      "border-radius": "8px",
      "object-fit": "cover"
    },
    src: $data.setting.leftImage
  }, null, 8, _hoisted_2)), _createElementVNode("div", _hoisted_3, [!$data.setting?.topImage ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    onClick: _cache[2] || (_cache[2] = $event => $options.config(1)),
    style: {
      "width": "168px",
      "height": "110px",
      "border-radius": "8px",
      "object-fit": "cover",
      "margin-bottom": "5px"
    },
    src: "https://cdn.waityou.online/c26ab7f9-8bb9-b020-b02a-637f490f6114.jpg"
  })) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    onClick: _cache[3] || (_cache[3] = $event => $options.config(1)),
    style: {
      "width": "168px",
      "height": "110px",
      "border-radius": "8px",
      "object-fit": "cover",
      "margin-bottom": "5px"
    },
    src: $data.setting.topImage
  }, null, 8, _hoisted_4)), !$data.setting?.bottomImage ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    onClick: _cache[4] || (_cache[4] = $event => $options.config(2)),
    style: {
      "width": "168px",
      "height": "110px",
      "border-radius": "8px",
      "object-fit": "cover"
    },
    src: "https://cdn.waityou.online/c26ab7f9-8bb9-b020-b02a-637f490f6114.jpg"
  })) : (_openBlock(), _createElementBlock("img", {
    key: 3,
    onClick: _cache[5] || (_cache[5] = $event => $options.config(2)),
    style: {
      "width": "168px",
      "height": "110px",
      "border-radius": "8px",
      "object-fit": "cover"
    },
    src: $data.setting.bottomImage
  }, null, 8, _hoisted_5))])]), _createVNode(_component_DialogView, {
    "data-id": 35,
    "bind-id": $data.type,
    onSubmit: $options.submitEdit,
    onClose: _cache[6] || (_cache[6] = $event => $data.editVisible = false),
    "visible-control": $data.editVisible
  }, null, 8, ["bind-id", "onSubmit", "visible-control"])], 64);
}