import DialogCustomView from "@/components/DialogCustomView";
import BatterTableDataView from "@/components/BatterTableDataView";
import QuestionCreateView from "@/components/question/QuestionCreateView";
import api from "@/api";
export default {
  name: "QuestionManageView",
  components: {
    QuestionCreateView,
    DialogCustomView,
    BatterTableDataView
  },
  data() {
    return {
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      updateId: 0,
      initQuery: {
        repoId: this.$route.params.repoId
      },
      createVisible: false,
      editVisible: false,
      repoId: this.$route.params.repoId
    };
  },
  methods: {
    createQuestion(data) {
      data['repoId'] = this.repoId;
      api.createQuestion(data).then(() => {
        this.createVisible = false;
        this.initQuery = {
          'refresh': new Date(),
          'repoId': this.$route.params.repoId
        };
      });
    },
    updateQuestion(data) {
      api.updateQuestion(data).then(() => {
        this.editVisible = false;
        this.initQuery = {
          'refresh': new Date(),
          'repoId': this.$route.params.repoId
        };
      });
    },
    onEditData(data) {
      this.updateId = data.id;
      this.editVisible = true;
    },
    onCreateMore() {
      this.createVisible = true;
    }
  }
};