import AnswerRecordCard from "@/components/exam/AnswerRecordCard";
import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "UserExamDetailView",
  components: {
    AnswerRecordCard,
    BatterTableDataView
  },
  data() {
    return {
      examId: this.$route.params.examId,
      userId: this.$route.params.userId,
      mode: this.$route.params.mode,
      list: [],
      activeId: 0,
      questionOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'L', 'N'],
      user: {}
    };
  },
  methods: {
    init() {
      api.getUserInfoById(this.userId).then(ret => {
        this.user = ret;
      });
      api.getUserAnswerRecords({
        userId: this.userId,
        examId: this.examId
      }).then(ret => {
        this.list = ret.list;
        this.activeId = ret.list && ret.list.length > 0 ? ret.list[0].recordId : 0;
      });
    }
  },
  mounted() {
    this.init();
  }
};