import api from "@/api";
// import ReportTableHeader from "@/components/report/ReportTableHeader";

export default {
  name: "ReportTableView",
  components: {},
  props: {
    reportType: {
      type: Number,
      default: 0
    },
    timeRange: {
      type: Array,
      default: () => {
        return [];
      }
    },
    keys: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    timeRange() {
      this.initTable();
    },
    keys() {
      this.initTable();
    }
  },
  data() {
    return {
      tableStructure: [],
      tableData: [],
      loading: false
    };
  },
  methods: {
    initTable() {
      this.loading = true;
      api.queryReportTableData({
        reportType: this.reportType,
        keys: this.keys,
        timeRange: this.timeRange
      }).then(ret => {
        this.loading = false;
        if (!ret) {
          return;
        }
        this.tableStructure = ret.headers;
        this.tableData = ret.list;
      });
    }
  },
  mounted() {
    this.initTable();
  }
};