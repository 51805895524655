import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import DialogView from "@/components/DialogView";
export default {
  name: "TeachTrendsView",
  components: {
    DialogView,
    BatterTableDataView
  },
  data() {
    return {
      initQuery: {},
      enableMap: {},
      auditVisible: false,
      auditId: 0
    };
  },
  methods: {
    submitAudit(data) {
      data.id = this.auditId;
      api.updateTrainActivityAudit(data).then(() => {
        this.auditVisible = false;
        this.$refs.trendsTable.fetchData();
      });
    },
    audit(id) {
      this.auditId = id;
      this.auditVisible = true;
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.enable;
      }
    },
    enableChange(id, val) {
      api.changeTrendEnable(id, val).then(() => {
        this.enableMap[id] = val;
      });
    },
    onEditData(data) {
      this.$router.push('/teach/trends/create/' + data.id);
    },
    go2createView() {
      this.$router.push('/teach/trends/create/0');
    }
  }
};